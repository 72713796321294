import React, { useEffect, useState, useRef } from 'react';

const Dropdown = ({ elements, choosen, setChoosen, error, size }) => {
  const ref = useRef();
  const [active, setActive] = useState('');
  const toggle = () => {
    if (active === 'active') {
      setActive('');
    } else {
      setActive('active');
    }
  };

  useEffect(() => {
    let outside_click = (e) => {
      if (!ref.current.contains(e.target)) {
        setActive('');
      }
    };
    document.addEventListener('mousedown', outside_click);
    return () => {
      document.removeEventListener('mousedown', outside_click);
    };
    // eslint-disable-next-line
  }, []);

  const choose = (el) => {
    setChoosen(el);
    setActive('');
  };

  if (size === undefined) {
    size = '';
  }

  return (
    <div ref={ref} className={`dropdown ${size} ${active} `}>
      <div
        className={
          error === true && (choosen === false || choosen === '')
            ? 'dropdown-btn error'
            : 'dropdown-btn'
        }
        onClick={toggle}
      >
        {choosen === false || choosen === '' ? '------' : choosen}
        <i className="fas fa-caret-down"></i>
      </div>
      <ul>
        {elements.map((el, index) => {
          return (
            <li key={index} onClick={() => choose(el)}>
              {el}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Dropdown;
