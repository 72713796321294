import React, { Fragment, useContext } from 'react';
import TnRow from './TnRow';
import { ehContext } from '../../context/eh/EhState';

const TnTable = ({ pagination, setPagination }) => {
  const eh_Context = useContext(ehContext);
  const { tn } = eh_Context;

  const click_sort = (condition) => {
    if (pagination === undefined) {
      console.log('no pagination definede');
      return;
    } else {
      if (pagination.type !== condition) {
        setPagination({
          ...pagination,
          page: 1,
          type: condition,
          direction: 'DESC',
        });
      } else {
        setPagination({
          ...pagination,
          page: 1,
          type: condition,
          direction: pagination.direction === 'DESC' ? 'ASC' : 'DESC',
        });
      }
    }
  };

  return (
    <Fragment>
      <div className="responsive-table">
        <table>
          <thead>
            <tr>
              <th>Nr</th>
              <th onClick={() => click_sort('name')}>
                Name <i className="fas fa-caret-down"></i>
              </th>
              <th className="rp-col-2" onClick={() => click_sort('bday')}>
                Geb. <i className="fas fa-caret-down"></i>
              </th>
              <th className="rp-col-lg" onClick={() => click_sort('anschrift')}>
                Anschrift <i className="fas fa-caret-down"></i>
              </th>
              <th className="rp-col-3" onClick={() => click_sort('date')}>
                Kurs <i className="fas fa-caret-down"></i>
              </th>
              <th className="rp-col-sm" onClick={() => click_sort('user')}>
                Erstellt <i className="fas fa-caret-down"></i>
              </th>
              <th
                className="rp-col-lg"
                onClick={() => click_sort('created_at')}
              >
                am <i className="fas fa-caret-down"></i>
              </th>
              <th className="rp-col-2" onClick={() => click_sort('bezahlt')}>
                Bezahlt <i className="fas fa-caret-down"></i>
              </th>
              <th
                className="rp-col-1"
                onClick={() => click_sort('teilgenommen')}
              >
                Teilnahme <i className="fas fa-caret-down"></i>
              </th>
            </tr>
          </thead>
          <tbody>
            {tn !== [] &&
              tn.map((one_tn, index) => {
                return (
                  <TnRow
                    key={one_tn.id}
                    tn={one_tn}
                    index={
                      pagination === undefined
                        ? index + 1
                        : pagination.page * pagination.tn_pro_seite -
                          pagination.tn_pro_seite +
                          index +
                          1
                    }
                  />
                );
              })}
          </tbody>
        </table>
      </div>
      {pagination !== undefined && (
        <Fragment>
          {pagination.date === '' && pagination.name === '' && (
            <div className="pagination">
              <div className="anzahl">{pagination.count} Teilnehmer</div>
              <div className="pages-btn">
                <div className="btn-arrows">
                  <i
                    onClick={() => {
                      if (pagination.page - 1 <= 0) {
                        return;
                      } else {
                        setPagination({
                          ...pagination,
                          page: pagination.page - 1,
                        });
                      }
                    }}
                    className="fas fa-angle-left"
                  ></i>
                </div>
                <div className="page-count">
                  {pagination.page}/
                  {Math.ceil(pagination.count / pagination.tn_pro_seite) === 0
                    ? 1
                    : Math.ceil(pagination.count / pagination.tn_pro_seite)}
                </div>
                <div className="btn-arrows">
                  <i
                    onClick={() => {
                      const x = Math.ceil(
                        pagination.count / pagination.tn_pro_seite
                      );
                      if (pagination.page + 1 > x) {
                        return;
                      } else {
                        setPagination({
                          ...pagination,
                          page: pagination.page + 1,
                        });
                      }
                    }}
                    className="fas fa-angle-right"
                  ></i>
                </div>
              </div>
            </div>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default TnTable;
