import React, { useState, Fragment } from 'react';
import WebsiteBezahlungNovalnet from './WebsiteBezahlungNovalnet';
const WebsiteAccordion = ({
  data,
  to_payment,
  bank,
  is_open,
  config,
  payments,
  is_alone,
}) => {
  const [isOpen, setOpen] = useState(is_open);
  const { title, type, img_src } = data;

  if (type === 'novalnet') {
    return (
      <Fragment>
        <div className="website-accordion-wrapper">
          {is_alone === false && (
            <div
              className={`accordion-title ${isOpen ? 'open' : ''}`}
              onClick={() => setOpen(!isOpen)}
            >
              {title}
            </div>
          )}

          <div className={`accordion-item ${!isOpen ? 'collapsed' : ''}`}>
            <div className="accordion-content">
              <WebsiteBezahlungNovalnet
                config={config}
                to_payment={to_payment}
                payments={payments}
              />
            </div>
          </div>
        </div>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <div className="website-accordion-wrapper">
          {is_alone === false && (
            <div
              className={`accordion-title ${isOpen ? 'open' : ''}`}
              onClick={() => setOpen(!isOpen)}
            >
              {title}
            </div>
          )}
          <div className={`accordion-item ${!isOpen ? 'collapsed' : ''}`}>
            <div className="accordion-content">
              {img_src !== null ? (
                <div className={'img-container ' + type}>
                  <img
                    onClick={() => {
                      to_payment(type, type);
                    }}
                    src={img_src}
                    alt="Ein Kurs zur Ersten Hilfe"
                  />
                </div>
              ) : (
                <div className="vorkasse">
                  {bank !== null && (
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <strong>Konto Inhaber:</strong>
                          </td>
                          <td>
                            <strong>{bank.holder}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>IBAN:</strong>
                          </td>
                          <td>
                            <strong>{bank.iban} </strong>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Betrag:</strong>
                          </td>
                          <td>
                            <strong>{bank.betrag} €</strong>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Verwendungszweck:</strong>
                          </td>
                          <td>
                            <strong>{bank.verwendungszweck}</strong>
                          </td>
                        </tr>
                        <tr>
                          <td>Bank:</td>
                          <td>{bank.name}</td>
                        </tr>
                        <tr>
                          <td>BIC:</td>
                          <td>{bank.swift}</td>
                        </tr>
                      </tbody>
                    </table>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
};

export default WebsiteAccordion;
