import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { ehContext } from '../../context/eh/EhState';
const BackButton = ({ padding }) => {
  const eh_Context = useContext(ehContext);
  const { clear_eh } = eh_Context;
  const history = useHistory();
  return (
    <div className={'text-center ' + padding}>
      <button
        onClick={() => {
          clear_eh();
          history.goBack();
        }}
      >
        Zurück
      </button>
    </div>
  );
};

export default BackButton;
