import React, { Fragment, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { handlersContext } from '../../context/handlers/HandlersState';
import { ehContext } from '../../context/eh/EhState';
import { useHistory } from 'react-router-dom';
import ConfirmStep from '../handlers/ConfirmStep';

const TeilnehmerExtra = ({ state, setState, id }) => {
  const history = useHistory();
  const eh_Context = useContext(ehContext);
  const { get_one_kurs } = eh_Context;
  const handlers_Context = useContext(handlersContext);
  const { set_message, wait, set_loader, remove_loader } = handlers_Context;
  const [bezahlung, setBezahlung] = useState([]);
  const [is_partners, setIs_Partners] = useState(false);
  const [is_dozent, setIs_Dozent] = useState(false);
  useEffect(() => {
    const fct = async () => {
      try {
        const res = await axios.get(`/teilnehmer_api/payment?id=${id}`);
        setBezahlung(res.data.result);
      } catch (err) {
        console.log(err.response.data);
        set_message(err.response.data.error_text);
        return;
      }
    };
    fct();
    // eslint-disable-next-line
  }, [state.bezahlt]);

  useEffect(() => {
    const check_if_dozent = async () => {
      try {
        const res = await axios.get(`/users_api/is_users?tn_id=${id}`);
        setIs_Partners(res.data.is_partners === 1 ? true : false);
        setIs_Dozent(res.data.is_dozent === 1 ? true : false);
        // setBezahlung(res.data.result);
      } catch (err) {
        console.log(err.response.data);
        set_message(err.response.data.error_text);
        return;
      }
    };
    check_if_dozent();
    // eslint-disable-next-line
  }, []);
  const onChange_checkbox = async (e) => {
    var { name, checked } = e.target;
    if (name === 'bezahlt') {
      try {
        set_loader();
        var data_bezahlt = {};
        if (checked) {
          data_bezahlt.status = 1;
        } else {
          data_bezahlt.status = 0;
        }
        await axios.put(`/teilnehmer_api/payment?id=${id}`, data_bezahlt);
        remove_loader();
      } catch (err) {
        remove_loader();
        if (err.response.data.error_type === 'kurs_full') {
          history.push('/fahrschule/umbuchen/nach-bezahlung/' + id);
          return;
        }
        set_message(err.response.data.error_text);

        return;
      }
    } else if (name === 'teilgenommen') {
      try {
        var data_teilgenommen = {};
        if (checked) {
          data_teilgenommen.status = 1;
        } else {
          data_teilgenommen.status = 0;
        }
        await axios.put(
          `/teilnehmer_api/teilgenommen?id=${id}`,
          data_teilgenommen
        );
      } catch (err) {
        console.log(err.response.data);
        set_message(err.response.data.error_text);
        return;
      }
    } else {
      return;
    }
    if (checked) {
      setState({
        ...state,
        [name]: 1,
      });
    } else {
      setState({
        ...state,
        [name]: 0,
      });
    }
  };

  const [confirm_delete, setConfirm_Delete] = useState({
    question: `Möchtest du den Teilnehmer ${state.vorname} wirklich löschen?`,
    confirm_text: 'Bestätigen',
    reject_text: 'Verwerfen',
    answer: false,
    active: false,
    type: 'delete_tn',
  });

  useEffect(() => {
    setConfirm_Delete({
      ...confirm_delete,
      question: `Möchtest du den Teilnehmer <span class="red-text">${state.vorname} ${state.nachname} (${state.birthday})</span> wirklich löschen?`,
    });
    // eslint-disable-next-line
  }, [state]);

  useEffect(() => {
    const fct = async () => {
      try {
        set_loader();
        await axios.delete(`/teilnehmer_api?id=${id}`);
        await wait();
        remove_loader();
        history.goBack();
      } catch (err) {
        console.log(err.response.data);
        set_message(err.response.data.error_text);
        remove_loader();
        return;
      }
    };
    if (confirm_delete.answer === true) {
      fct();
    } else {
      return;
    }
    // eslint-disable-next-line
  }, [confirm_delete.answer]);

  const download = async (name) => {
    try {
      set_loader();
      const res = await axios.get(
        `/teilnehmer_api/downloads/${name}?id=${id}`,
        {
          responseType: 'blob',
          headers: {
            Accept: 'application/pdf',
          },
        }
      );
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      var file_name = `${name}-${state.nachname}.pdf`;
      link.setAttribute('download', file_name.toUpperCase());
      document.body.appendChild(link);
      link.click();
      remove_loader();
    } catch (err) {
      console.log(err.response.data);
      set_message(err.response.data.error_text);
      remove_loader();
      return;
    }
  };

  const auf_warteliste = async () => {
    try {
      set_loader();
      const data = {
        tn_id: id,
      };
      const res = await axios.put(`/umbuchen_api/warteliste`, data);
      get_one_kurs(res.data.warteliste_id);
      await wait(1000);
      remove_loader();
    } catch (err) {
      console.log(err.response.data);
      set_message(err.response.data.error_text);
      remove_loader();
      return;
    }
  };

  return (
    <Fragment>
      <ConfirmStep confirm={confirm_delete} setConfirm={setConfirm_Delete} />
      <div className="teilnehmer-extra">
        <p className="text-center pb-2">Adminbereich</p>
        <div className="checkbox-container">
          <div className="one-checkbox-container">
            <div className="round">
              <input
                type="checkbox"
                id="checkbox-payed-extra"
                className="mr-2"
                name="bezahlt"
                checked={state.bezahlt === 1 ? true : false}
                onChange={onChange_checkbox}
              />
              <label htmlFor="checkbox-payed-extra"></label>
            </div>
            <span className="ml-2">bezahlt</span>
          </div>
          <div className="one-checkbox-container">
            <div className="round">
              <input
                type="checkbox"
                id="checkbox-teilgenommen-extra"
                className="mr-2"
                name="teilgenommen"
                checked={state.teilgenommen === 1 ? true : false}
                onChange={onChange_checkbox}
              />
              <label htmlFor="checkbox-teilgenommen-extra"></label>
            </div>
            <span className="ml-2">teilgenommen</span>
          </div>
        </div>

        {is_partners === true && (
          <div className="btn-container-3">
            <button
              className="btn-green"
              onClick={() => {
                history.push('/fahrschule/umbuchen/' + id);
              }}
            >
              umbuchen
            </button>
            <button className="btn-green" onClick={auf_warteliste}>
              auf Warteliste
            </button>
            <button
              className="btn-red"
              onClick={() => {
                setConfirm_Delete({
                  ...confirm_delete,
                  active: true,
                });
              }}
            >
              Löschen
            </button>
          </div>
        )}

        <hr />
        {is_dozent === true && (
          <Fragment>
            <p className="text-center pb-2">Dokumente drucken</p>
            <div className="btn-container-3 ">
              <button className="btn-blue" onClick={() => download('quittung')}>
                Quittung
              </button>
              <button
                className="btn-blue"
                onClick={() => download('bescheinigung')}
              >
                Bescheinigung
              </button>
              <button
                className="btn-blue"
                onClick={() => download('datenblatt')}
              >
                Datenblatt
              </button>
            </div>
            <div className="btn-container-3 pt-2">
              <button
                className="btn-blue"
                onClick={() => download('bescheinigung-vorlage')}
              >
                Bescheinigung mit Vorlage
              </button>
            </div>

            <hr />
          </Fragment>
        )}

        <p className="text-center">Buchungsinformation</p>
        <div className="responsive-table">
          <table
            style={{
              margin: '10px auto',
            }}
          >
            <thead>
              <tr>
                <th>Nr</th>
                <th>status</th>
                <th className="rp-col-3">am</th>
                <th className="rp-col-2">von</th>
                <th className="rp-col-2">Art</th>
                <th>betrag</th>
                <th className="rp-col-1">Rabatt </th>
              </tr>
            </thead>
            <tbody>
              {bezahlung !== [] &&
                bezahlung.map((item, index) => {
                  return (
                    <tr
                      key={index}
                      style={{
                        cursor: 'default',
                      }}
                    >
                      <td>{index + 1}</td>
                      <td>{item.status === 1 ? 'bezahlt' : 'unbezahlt'}</td>
                      <td className="rp-col-3">
                        {item.date} {item.time}
                      </td>
                      <td className="rp-col-2">{item.user_name}</td>
                      <td className="rp-col-2">
                        {item.method === '' ? 'offen' : item.method}
                      </td>
                      <td>{item.betrag} €</td>
                      <td className="rp-col-1">
                        {item.rabatt} {item.type === 'in_euro' ? '€' : '%'}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </Fragment>
  );
};

export default TeilnehmerExtra;
