import React, { Fragment } from 'react';
import Calendar from '../kurse/Calendar';

const KursWeitereTermine = ({ state, setState }) => {
  return (
    <Fragment>
      {Object.keys(state).map((key, i) => {
        if (state[key] !== null) {
          return (
            <div
              className="py-2"
              key={i}
              style={{
                display: 'flex',
              }}
            >
              <Calendar
                name={key}
                state={state}
                setState={setState}
                error={false}
              />
              <button
                className="btn-red"
                style={{
                  fontSize: '0.8rem',
                  padding: '0.3rem 0.6rem',
                }}
                onClick={() => {
                  setState({
                    ...state,
                    [key]: null,
                  });
                }}
              >
                <i className="far fa-trash-alt"></i>
              </button>
            </div>
          );
        } else {
          return null;
        }
      })}
    </Fragment>
  );
};

export default KursWeitereTermine;
