class Checks {
  static email(value) {
    if (value === false) {
      return false;
    }
    // eslint-disable-next-line
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(value.trim()) && value.trim() !== '') {
      return true;
    } else {
      return false;
    }
  }
  static not_empty(value) {
    if (value.trim() !== '') {
      return true;
    } else {
      return false;
    }
  }
  static phone(phone) {
    if (phone === false) {
      return false;
    }
    // eslint-disable-next-line
    const re = /^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/;
    const size = phone.trim().length;
    if (re.test(phone.trim()) && phone.trim() !== '' && size > 8 && size < 20) {
      return true;
    } else {
      return false;
    }
  }

  static birthday(day) {
    if (day === false) {
      return false;
    }
    if (day === '' || day.length !== 10) {
      return false;
    } else {
      return true;
    }
  }
  static plz(val) {
    if (val === false) {
      return false;
    }
    let bool = true;
    function isNormalInteger(str) {
      var n = Math.floor(Number(str));
      return n !== Infinity && String(n) === str && n >= 0;
    }
    if (val === '') {
      bool = false;
      return false;
    }
    if (val.length !== 5) {
      bool = false;
    }
    if (!isNormalInteger(val)) {
      bool = false;
    }
    if (!bool) {
      return false;
    } else {
      return true;
    }
  }

  // images
  static file_size(file, boundary = 1000000) {
    const size = file.size;
    if (size > boundary) {
      return false;
    } else {
      return true;
    }
  }

  static file_format(
    file,
    valid_format = ['image/jpeg', 'image/jpg', 'application/pdf']
  ) {
    const type = file.type;
    let bool = false;
    valid_format.forEach((each) => {
      if (each === type) {
        bool = true;
      }
    });
    return bool;
  }
}

export default Checks;
