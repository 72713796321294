import React, { useState, useContext, Fragment } from 'react';
import axios from 'axios';
import Checks from '../../utils/Checks';
import { handlersContext } from '../../context/handlers/HandlersState';
import { useHistory } from 'react-router-dom';
import ImagePopUp from '../handlers/ImagePopUp';
import WebsiteCaptchaGutschein from './WebsiteCaptchaGutschein';
import WebsiteShowcase from './WebsiteShowcase';
import WebsiteForm from './WebsiteForm';
import WebsiteFooter from './WebsiteFooter';

const WebsiteBuchen = ({ id }) => {
  const history = useHistory();

  const handlers_Context = useContext(handlersContext);
  const {
    set_loader,
    set_message,
    remove_loader,
    wait,
    is_clicked,
    set_is_clicked,
  } = handlers_Context;
  const [error, setError] = useState(false);
  const [error_text, setError_Text] = useState('');
  const [state, setState] = useState({
    //in info
    anrede: false,
    vorname: false,
    nachname: false,
    street: false,
    plz: false,
    city: false,
    birthday: false,
    email: false,
    phone: false,
    // extra
    gutschein: '',
    captcha: '',
    captcha_crypted: '',
    law: 0,
  });

  const submit = async () => {
    if (is_clicked === true) {
      console.log('Double click');
      return;
    }
    set_is_clicked(true);
    setError(true);
    var valid = true;
    for (const prop in state) {
      if (
        (state[prop] === false || state[prop] === '') &&
        prop !== 'gutschein' &&
        prop !== 'captcha' &&
        prop !== 'captcha_crypted' &&
        prop !== 'law'
      ) {
        valid = false;
      }
    }
    var error_text = 'Bitte fülle alle Felder aus!';
    var extra_checks = true;
    if (!Checks.email(state.email)) {
      error_text = 'Bitte wähle eine gültige E-Mail-Adresse';
      extra_checks = false;
    } else if (!Checks.phone(state.phone)) {
      error_text = 'Bitte wähle eine gültige Telefonnummer';
      extra_checks = false;
    } else if (!Checks.plz(state.plz)) {
      error_text = 'Bitte wähle eine gültige Postleitzahl';
      extra_checks = false;
    } else if (!Checks.birthday(state.birthday)) {
      error_text = 'Bitte wähle ein gültiges Geburtsdatum';
      extra_checks = false;
    }
    if (valid === false && extra_checks === false) {
      error_text = 'Bitte fülle alle Felder aus!';
    }

    if (valid === false || extra_checks === false) {
      console.log(state);

      setError_Text(error_text);
      return set_is_clicked(false);
    } else {
      if (state.law === 0) {
        return set_is_clicked(false);
      }
      if (state.captcha === '') {
        setError_Text('Bitte fülle alle Felder aus!');
        return set_is_clicked(false);
      }
      await send();
    }
  };

  const send = async () => {
    setError_Text('');
    set_loader();
    console.log(state);
    try {
      const res = await axios.post(`/website_api/post_tn?kurs_id=${id}`, state);
      await wait(1000);
      remove_loader();
      set_is_clicked(false);
      if (res.data.redirect_type === 'to_payment_page') {
        history.push('/eh/bezahlung/' + res.data.id);
      } else if (res.data.redirect_type === 'to_success_page') {
        history.push('/eh/erfolg/' + res.data.id);
      } else {
        history.push('/eh/bezahlung/' + res.data.id);
      }
    } catch (err) {
      console.log(err.response.data);
      if (err.response.data.hasOwnProperty('error_type')) {
        if (err.response.data.error_type === 'gutschein') {
          setState({
            ...state,
            gutschein: '',
          });
          set_message(err.response.data.error_text);
        } else if (err.response.data.error_type === 'invalid_captcha') {
          console.log('wrong captch');
          new_captcha();
        } else if (err.response.data.error_type === 'schon_angemeldet') {
          await wait();
          set_message(err.response.data.error_text);
          if (err.response.data.is_payed === 1) {
            history.push('/eh/erfolg/' + err.response.data.id);
          } else {
            history.push('/eh/bezahlung/' + err.response.data.id);
          }
          remove_loader();
          return set_is_clicked(false);
        } else {
          set_message(err.response.data.error_text);
        }
      } else {
        set_message(err.response.data.error_text);
      }

      remove_loader();
      return set_is_clicked(false);
    }
  };
  const [captcha_src, setCaptcha_Src] = useState(null);
  const new_captcha = async () => {
    try {
      const res = await axios.get(
        `/captcha_api/refresh?crypted=${state.captcha_crypted}`
      );
      setState({
        ...state,
        captcha_crypted: res.data.crypted,
        captcha: '',
      });
      setCaptcha_Src(res.data.display);
    } catch (err) {
      console.log(err.response.data);
    }
  };
  return (
    <Fragment>
      <ImagePopUp />
      <section className="website-admin">
        <div className="container py-2">
          <h2>Du hast dich für folgenden Kurs entschieden</h2>

          <WebsiteShowcase id={id} show_open={true} is_tn={false} />
          <WebsiteForm state={state} setState={setState} error={error} />

          <Fragment>
            <div className="py-3"></div>
            <WebsiteCaptchaGutschein
              state={state}
              setState={setState}
              submit={submit}
              error_text={error_text}
              error={error}
              new_captcha={new_captcha}
              captcha_src={captcha_src}
              setCaptcha_Src={setCaptcha_Src}
            />
          </Fragment>
        </div>
      </section>
      <WebsiteFooter />
    </Fragment>
  );
};

export default WebsiteBuchen;
