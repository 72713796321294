import React from 'react';

const WebsiteFooter = () => {
  var date = new Date();
  const year = date.getFullYear();
  return (
    <footer className="website-footer">
      <div className="container p-4">
        <div className="row text-center">
          <div className="col-sm-12">
            <div className="row">
              <div className="col-sm-3 pb-2 ">
                <p
                  className="footer-btn"
                  onClick={() => {
                    window.location.href = '/datenschutz';
                  }}
                >
                  Datenschutz
                </p>
              </div>
              <div className="col-sm-3 pb-2">
                <p
                  className="footer-btn"
                  onClick={() => {
                    window.location.href = '/impressum';
                  }}
                >
                  Impressum
                </p>
              </div>
              <div className="col-sm-3 pb-2">
                <p
                  className="footer-btn"
                  onClick={() => {
                    window.location.href = '/agb';
                  }}
                >
                  AGB
                </p>
              </div>
              <div className="col-sm-3 pb-2">
                <p
                  className="footer-btn"
                  onClick={() => {
                    window.location.href = '/faq';
                  }}
                >
                  FAQ
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row py-5">
        <div className="col-6 mx-auto">
          <p className="copyright text-center">
            <span>{year}</span> {'\u00A9'} Erste Hilfe
          </p>
        </div>
      </div>
    </footer>
  );
};

export default WebsiteFooter;
