import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { authContext } from '../context/auth/AuthState';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const auth_Context = useContext(authContext);
  const { is_auth, is_loading } = auth_Context;
  let result = null;
  if (is_loading === false) {
    result = (
      <Route
        {...rest}
        render={(props) =>
          !is_auth && !is_loading ? (
            <Redirect to="/fahrschule/login" />
          ) : (
            <Component {...props} />
          )
        }
      />
    );
  }
  return result;
};

export default PrivateRoute;
