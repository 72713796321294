import React, { useContext, useEffect, useState, Fragment } from 'react';
import axios from 'axios';
import { handlersContext } from '../../context/handlers/HandlersState';
import { useHistory } from 'react-router-dom';
import StuffCalendar from '../stuff/StuffCalendar';
import Dropdown from '../stuff/Dropdown';
import ConfirmStep from '../handlers/ConfirmStep';

const GutscheineAdmin = ({ action, id }) => {
  const history = useHistory();
  const handlers_Context = useContext(handlersContext);
  const {
    set_loader,
    set_message,
    remove_loader,
    wait,
    is_clicked,
    set_is_clicked,
  } = handlers_Context;
  const [state, setState] = useState({
    id: 0,
    status: 'active',
    type: 'in_euro',
    code: false,
    rabatt: false,
    anzahl: false,
    expiration_date: false,
    gebucht: 0,
    bezahlt: 0,
  });
  const [confirm_delete, setConfirm_Delete] = useState({
    question: 'Möchtest du diese Rabattaktion wirklich löschen',
    confirm_text: 'Bestätigen',
    reject_text: 'Verwerfen',
    answer: false,
    active: false,
    type: 'delete_rabatt',
  });
  const [error, setError] = useState(false);
  const [error_text, setError_Text] = useState('');
  const set_calendar = (key) => {
    const fct = (val) => {
      setState({
        ...state,
        [key]: val,
      });
    };
    return fct;
  };
  const onChange = (e) => {
    var { name, value } = e.target;
    if (name === 'anzahl' || name === 'rabatt') {
      value = value.replace(/,/g, '.');

      var input = Number(value);
      if (isNaN(input)) {
        return;
      } else {
        if (name === 'anzahl') {
          value = String(Math.floor(value));
        } else {
          value = value.replace(/\./g, ',');
        }
      }
    }
    if (value === '') {
      value = false;
    }
    setState({
      ...state,
      [name]: value,
    });
  };
  const onFocus = (e) => {
    e.target.classList.remove('error');
  };

  const randomCode = () => {
    var result = '';
    const length = 8;
    var characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    setState({
      ...state,
      code: result,
    });
  };
  useEffect(() => {
    const load_gutschein = async () => {
      try {
        const res_kurs = await axios.get(`/gutscheine_api?id=${id}`);
        const result = res_kurs.data.result;
        if (result === null) {
          return;
        } else {
          setState(result);
        }
      } catch (error) {
        console.log(error.response.data);
        set_message(error.response.data.error_text);
        return;
      }
    };
    if (action === 'edit') {
      load_gutschein();
    }
    // eslint-disable-next-line
  }, []);
  const submit = async () => {
    if (is_clicked === true) {
      console.log('Double click');
      return;
    }
    set_is_clicked(true);
    setError(true);
    var valid = true;
    for (const prop in state) {
      if (state[prop] === false || state[prop] === '') {
        valid = false;
      }
    }
    console.log(state);
    if (!valid) {
      setError_Text('Bitte fülle alle Felder aus!');
      return set_is_clicked(false);
    } else {
      setError_Text('');
      set_loader();
      try {
        var res = null;
        if (action === 'create') {
          res = await axios.post(`/gutscheine_api`, state);
        } else if (action === 'edit') {
          res = await axios.put(`/gutscheine_api?id=${id}`, state);
        }
        console.log(res.data);
        await wait();
        remove_loader();
        set_is_clicked(false);
        history.goBack();
      } catch (err) {
        remove_loader();
        set_message(err.response.data.error_text);
        return set_is_clicked(false);
      }
    }
  };

  const change_status = async (new_status) => {
    if (is_clicked === true) {
      return;
    }
    set_is_clicked(true);
    set_loader();
    try {
      var data = state;
      data = { ...data, status: new_status };
      await axios.put(`/gutscheine_api?id=${id}`, data);
      await wait();
      remove_loader();
      set_is_clicked(false);
      setState({
        ...state,
        status: new_status,
      });
    } catch (err) {
      remove_loader();
      set_message(err.response.data.error_text);
      console.log(err.response.data);
      return set_is_clicked(false);
    }
  };
  const delete_fct = async () => {
    if (is_clicked === true) {
      return;
    }
    set_is_clicked(true);
    set_loader();
    try {
      await axios.delete(`/gutscheine_api?id=${id}`);
      await wait();
      remove_loader();
      set_is_clicked(false);
      history.goBack();
    } catch (err) {
      remove_loader();
      set_message(err.response.data.error_text);
      console.log(err.response.data);
      return set_is_clicked(false);
    }
  };

  useEffect(() => {
    if (confirm_delete.answer === true) {
      delete_fct();
    }
    // eslint-disable-next-line
  }, [confirm_delete.answer]);

  const set_dropdown = (el) => {
    setState({
      ...state,
      type: el === 'Euro' ? 'in_euro' : 'in_percent',
    });
  };

  return (
    <Fragment>
      <ConfirmStep confirm={confirm_delete} setConfirm={setConfirm_Delete} />
      <div className="gutschein-admin pb-5">
        <div className="container gutschein-container">
          <div className="text-center pb-2">
            <button
              onClick={() => {
                history.goBack();
              }}
            >
              Zurück
            </button>
          </div>
          <h1 className="text-center pt-1 p2-4">
            {action === 'create'
              ? 'Rabattcode erstellen'
              : `Rabattcode bearbeiten`}
          </h1>
          {action === 'edit' && (
            <Fragment>
              <div className="statistik-container btn-container-3 pb-3">
                <p>
                  Status:
                  <span>{state.status === 'active' ? 'aktiv' : 'inaktiv'}</span>
                </p>
                <p>
                  bezahlt: <span>{state.bezahlt}</span>
                </p>
                <p>
                  gebucht: <span>{state.gebucht}</span>
                </p>
              </div>
              <div className="btn-container-3">
                <button
                  onClick={() => {
                    history.push('/fahrschule/gutscheine/teilnehmer/' + id);
                  }}
                >
                  Teilnehmer
                </button>
                {state.status === 'active' ? (
                  <button onClick={() => change_status('inactive')}>
                    inaktieveren
                  </button>
                ) : (
                  <button onClick={() => change_status('active')}>
                    aktivieren
                  </button>
                )}

                <button
                  onClick={() => {
                    setConfirm_Delete({
                      ...confirm_delete,
                      active: true,
                    });
                  }}
                  className="btn-red"
                >
                  Löschen
                </button>
              </div>
            </Fragment>
          )}

          <div className="stuff-calendar-container pt-3">
            <p className="pb-2 termin-text">Ablaufdatum: (inklusive)</p>
            <StuffCalendar
              choosen={state.expiration_date}
              setChoosen={set_calendar('expiration_date')}
              error={error}
            />
          </div>

          <div className="input-container pt-4">
            <p>Aktion in: </p>
            <Dropdown
              elements={['Euro', 'Prozent']}
              choosen={state.type === 'in_euro' ? 'Euro' : 'Prozent'}
              setChoosen={set_dropdown}
              error={error}
            />
          </div>

          <div className="input-container pt-4">
            <div className="row">
              <div className="col-lg-4 pt-2">
                <p>
                  Rabattcode:{' '}
                  <i
                    onClick={randomCode}
                    className="spinner fas fa-sync-alt"
                  ></i>
                </p>
                <input
                  className={
                    error === true && state.code === false ? 'error' : ''
                  }
                  onFocus={onFocus}
                  onChange={onChange}
                  name="code"
                  type="text"
                  value={!state.code ? '' : state.code}
                />
              </div>

              <div className="col-lg-4 pt-2">
                <p>Rabatt in {state.type === 'in_euro' ? '€' : '%'}:</p>
                <input
                  className={
                    error === true && state.rabatt === false ? 'error' : ''
                  }
                  onFocus={onFocus}
                  onChange={onChange}
                  name="rabatt"
                  type="text"
                  value={!state.rabatt ? '' : state.rabatt}
                />
              </div>
              <div className="col-lg-4  pt-2">
                <p>Anzahl:</p>
                <input
                  className={
                    error === true && state.anzahl === false ? 'error' : ''
                  }
                  onFocus={onFocus}
                  onChange={onChange}
                  name="anzahl"
                  type="text"
                  value={!state.anzahl ? '' : state.anzahl}
                />
              </div>
            </div>
          </div>
          <div className="text-center py-4">
            <div
              className={
                error_text !== '' ? 'error-text pb-2' : 'error-text hide pb-2'
              }
            >
              {error_text}
            </div>

            <button onClick={submit} className="btn-green">
              {action === 'create' ? 'Erstellen' : 'Speichern'}
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default GutscheineAdmin;
