import React from 'react';

const NotFound = ({ redirect_btn }) => {
  return (
    <div>
      <div className="container">
        <h1 className="text-center py-3">Diese Seite existiert nicht!</h1>
        {redirect_btn && (
          <div className="py-3 text-center">
            <button
              onClick={() => {
                window.location.href = '/';
              }}
            >
              Zur Startseite
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default NotFound;
