import './scss/main.scss';
import React, { Fragment, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import PrivateRoute from './utils/PrivateRoute';
import Navbar from './components/layout/Navbar';
import Loader from './components/handlers/Loader';
import Message from './components/handlers/Message';
import Login from './components/users/Login';
import Home from './components/pages/Home';
import OneKurs from './components/pages/OneKurs';
import KursAdmin from './components/kurse/KursAdmin';
import NotFound from './components/pages/NotFound';
import EhState from './context/eh/EhState';
import AuthState from './context/auth/AuthState';
import HandlersState from './context/handlers/HandlersState';

import TeilnehmerAdmin from './components/tn/TeilnehmerAdmin';
import on_load from './utils/on_load';
import ScrollToTop from './utils/ScrollToTop';
import Suche from './components/pages/Suche';
import Partner from './components/users/Partner';
import TnFromPartner from './components/pages/TnFromPartner';
import PartnerAdmin from './components/users/PartnerAdmin';
import WartelisteAdmin from './components/kurse/WartelisteAdmin';
import GutscheineTn from './components/gutscheine/GutscheineTn';
import GutscheineAdmin from './components/gutscheine/GutscheineAdmin';
import Gutscheine from './components/gutscheine/Gutscheine';
import Admin from './components/admin/Admin';
import UmbuchenAdmin from './components/umbuchen/UmbuchenAdmin';
import WebsiteBezahlung from './components/website/WebsiteBezahlung';
import WebsiteBuchen from './components/website/WebsiteBuchen';
import WebsiteUmbuchen from './components/website/WebsiteUmbuchen';
import WebsiteErfolg from './components/website/WebsiteErfolg';
import WebsiteUmbuchenTooLate from './components/website/WebsiteUmbuchenTooLate';

on_load();
function App() {
  // to  prevent keyframes on first
  useEffect(() => {
    const preload = document.querySelector('#preload');
    if (preload !== null) {
      setTimeout(() => {
        preload.className = '';
      }, 1000);
    }
    // eslint-disable-next-line
  }, []);
  return (
    <AuthState>
      <HandlersState>
        <EhState>
          <Router>
            <ScrollToTop>
              <Fragment>
                <div id="preload" className="preload">
                  <div className="mobile-overflow-wrapper">
                    <Navbar />
                    <div className="main-container-admin">
                      <Switch>
                        <PrivateRoute
                          exact
                          path="/fahrschule"
                          component={Home}
                        />
                        <PrivateRoute
                          exact
                          path="/fahrschule/kurs/:kurs_id"
                          component={(props) => (
                            <OneKurs id={props.match.params.kurs_id} />
                          )}
                        />
                        <PrivateRoute
                          exact
                          path="/fahrschule/neuer-kurs"
                          component={() => (
                            <KursAdmin action={'create'} id={null} />
                          )}
                        />
                        <PrivateRoute
                          exact
                          path="/fahrschule/kurs-bearbeiten/:kurs_id"
                          component={(props) => (
                            <KursAdmin
                              action={'edit'}
                              id={props.match.params.kurs_id}
                            />
                          )}
                        />
                        <PrivateRoute
                          exact
                          path="/fahrschule/neue-warteliste"
                          component={() => (
                            <WartelisteAdmin action={'create'} id={null} />
                          )}
                        />
                        <PrivateRoute
                          exact
                          path="/fahrschule/warteliste-bearbeiten/:kurs_id"
                          component={(props) => (
                            <WartelisteAdmin
                              action={'edit'}
                              id={props.match.params.kurs_id}
                            />
                          )}
                        />
                        <PrivateRoute
                          exact
                          path="/fahrschule/teilnehmer-buchen/:kurs_id"
                          component={(props) => (
                            <TeilnehmerAdmin
                              action={'create'}
                              id={props.match.params.kurs_id}
                            />
                          )}
                        />
                        <PrivateRoute
                          exact
                          path="/fahrschule/teilnehmer-bearbeiten/:id"
                          component={(props) => (
                            <TeilnehmerAdmin
                              action={'edit'}
                              id={props.match.params.id}
                            />
                          )}
                        />
                        <PrivateRoute
                          exact
                          path="/fahrschule/suche"
                          component={Suche}
                        />
                        <PrivateRoute
                          exact
                          path="/fahrschule/partner"
                          component={Partner}
                        />
                        <PrivateRoute
                          exact
                          path="/fahrschule/teilnehmer-partner/:partner_id"
                          component={(props) => (
                            <TnFromPartner id={props.match.params.partner_id} />
                          )}
                        />
                        <PrivateRoute
                          exact
                          path="/fahrschule/neuer-partner"
                          action={'create'}
                          id={false}
                          component={(props) => (
                            <PartnerAdmin action={'create'} id={false} />
                          )}
                        />

                        <PrivateRoute
                          exact
                          path="/fahrschule/partner-bearbeiten/:user_id"
                          action={'create'}
                          id={false}
                          component={(props) => (
                            <PartnerAdmin
                              action={'edit'}
                              id={props.match.params.user_id}
                            />
                          )}
                        />
                        <PrivateRoute
                          exact
                          path="/fahrschule/fahrschule-admin"
                          component={(props) => <Admin />}
                        />
                        <PrivateRoute
                          exact
                          path="/fahrschule/gutscheine"
                          component={(props) => <Gutscheine />}
                        />
                        <PrivateRoute
                          exact
                          path="/fahrschule/gutscheine/teilnehmer/:gutschein_id"
                          component={(props) => (
                            <GutscheineTn
                              id={props.match.params.gutschein_id}
                            />
                          )}
                        />
                        <PrivateRoute
                          exact
                          path="/fahrschule/gutscheine/erstellen"
                          component={(props) => (
                            <GutscheineAdmin action={'create'} id={false} />
                          )}
                        />

                        <PrivateRoute
                          exact
                          path="/fahrschule/gutscheine/bearbeiten/:user_id"
                          component={(props) => (
                            <GutscheineAdmin
                              action={'edit'}
                              id={props.match.params.user_id}
                            />
                          )}
                        />
                        {/* Umbuchen */}
                        <PrivateRoute
                          exact
                          path="/fahrschule/umbuchen/:tn_id"
                          component={(props) => (
                            <UmbuchenAdmin
                              action={'voluntary'}
                              id={props.match.params.tn_id}
                            />
                          )}
                        />
                        <PrivateRoute
                          exact
                          path="/fahrschule/umbuchen/nach-bezahlung/:tn_id"
                          component={(props) => (
                            <UmbuchenAdmin
                              action={'too_late'}
                              id={props.match.params.tn_id}
                            />
                          )}
                        />
                        {/* // Website stuff */}
                        <PrivateRoute
                          exact
                          path="/eh/buchen/:kurs_id"
                          component={(props) => (
                            <WebsiteBuchen id={props.match.params.kurs_id} />
                          )}
                        />
                        <PrivateRoute
                          exact
                          path="/eh/umbuchen/:tn_id"
                          component={(props) => (
                            <WebsiteUmbuchen id={props.match.params.tn_id} />
                          )}
                        />

                        <PrivateRoute
                          exact
                          path="/eh/umbuchen-zu-spaet/:tn_id"
                          component={(props) => (
                            <WebsiteUmbuchenTooLate
                              id={props.match.params.tn_id}
                            />
                          )}
                        />
                        <PrivateRoute
                          exact
                          path="/eh/bezahlung/:tn_id"
                          component={(props) => (
                            <WebsiteBezahlung id={props.match.params.tn_id} />
                          )}
                        />
                        <PrivateRoute
                          exact
                          path="/eh/erfolg/:tn_id"
                          component={(props) => (
                            <WebsiteErfolg id={props.match.params.tn_id} />
                          )}
                        />

                        <Route
                          exact
                          path="/fahrschule/login"
                          component={Login}
                        />
                        <Route
                          component={(props) => (
                            <NotFound redirect_btn={false} />
                          )}
                        />
                      </Switch>
                    </div>
                    <Loader />
                    <Message />
                  </div>
                </div>
              </Fragment>
            </ScrollToTop>
          </Router>
        </EhState>
      </HandlersState>
    </AuthState>
  );
}

export default App;
