import React, { Fragment, useContext } from 'react';
import { ehContext } from '../../context/eh/EhState';
import { useHistory } from 'react-router-dom';
const PartnerRow = ({ partner, index }) => {
  const history = useHistory();
  const eh_Context = useContext(ehContext);
  const { set_one_partner, set_tn } = eh_Context;
  return (
    <Fragment>
      <tr
        onClick={(e) => {
          if (['teilnehmer'].includes(e.target.dataset.row)) {
            return;
          } else {
            if (partner.status !== 'admin') {
              set_one_partner(partner.id);
              history.push(`/fahrschule/partner-bearbeiten/${partner.id}`);
            } else {
              set_tn([]);
              history.push(`/fahrschule/teilnehmer-partner/${partner.id}`);
            }
          }
        }}
      >
        <td className="text-center">{index + 1} </td>
        <td>{partner.name} </td>
        <td>{partner.status} </td>
        <td className="rp-col-2">{partner.email}</td>
        <td className="rp-col-1">
          {partner.status !== 'admin' && partner.password}
        </td>
        <td
          className="rp-col-1"
          data-row="teilnehmer"
          onClick={() => {
            set_tn([]);
            history.push(`/fahrschule/teilnehmer-partner/${partner.id}`);
          }}
        >
          {partner.bezahlt} / {partner.gebucht}{' '}
        </td>
      </tr>
    </Fragment>
  );
};

export default PartnerRow;
