import React, { Fragment, useRef, useEffect, useContext } from 'react';
import { ehContext } from '../../context/eh/EhState';
import { authContext } from '../../context/auth/AuthState';
import { useHistory } from 'react-router-dom';
const BoxKurs = ({ box, setBox }) => {
  const auth_Context = useContext(authContext);
  const { user_status } = auth_Context;
  const eh_Context = useContext(ehContext);
  const { one_kurs, set_tn } = eh_Context;
  let box_ref = useRef();
  let history = useHistory();
  useEffect(() => {
    let outside_click = (e) => {
      if (!box_ref.current.contains(e.target)) {
        setBox(false);
        // set_one_kurs(null);
      }
    };
    document.addEventListener('mousedown', outside_click);
    return () => {
      document.removeEventListener('mousedown', outside_click);
    };
  });
  return (
    <div className={`extra_handler_box ${box === true ? 'active' : ''}`}>
      <div ref={box_ref} className="content">
        <div className="close">
          <i
            className="far fa-times-circle"
            onClick={() => {
              setBox(false);
              //   set_one_kurs(null);
            }}
          ></i>
        </div>
        <div className="content-top-box">
          <p className="pb-4">
            <span className="name">
              {one_kurs !== null && (
                <Fragment>
                  Kurs: {one_kurs.stadt} {one_kurs.date}
                </Fragment>
              )}
            </span>
          </p>
          {user_status === 'admin' && (
            <p>
              <button
                className="btn-green btn-100 kurs_details_btn"
                onClick={() => {
                  if (one_kurs.date === 'Warteliste') {
                    history.push(
                      `/fahrschule/warteliste-bearbeiten/${one_kurs.id}`
                    );
                  } else {
                    history.push(`/fahrschule/kurs-bearbeiten/${one_kurs.id}`);
                  }
                }}
              >
                Bearbeiten
              </button>
            </p>
          )}
          <p>
            <button
              className="btn-3 btn-100 kurs_teilnehmer_btn"
              onClick={() => {
                set_tn([]);
                history.push(`/fahrschule/kurs/${one_kurs.id}`);
              }}
            >
              Teilnehmer
            </button>
          </p>
          <p>
            <button
              className="btn-100 kurs_buchen_btn"
              onClick={() => {
                history.push(`/fahrschule/teilnehmer-buchen/${one_kurs.id}`);
              }}
            >
              Buchen
            </button>
          </p>
          {/* <p>
            <button
              className="btn-100 btn-red"
              onClick={() => {
                history.push(`/eh/buchen/${one_kurs.id}`);
              }}
            >
              Buchen Website
            </button>
          </p> */}
        </div>
      </div>
    </div>
  );
};

export default BoxKurs;
