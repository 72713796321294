import {
  REMOVE_MESSAGE,
  SET_LOADER,
  SET_MESSAGE,
  REMOVE_LOADER,
  SET_IS_CLICKED,
  SET_IMAGE_SRC,
  SET_PDF_SRC,
} from '../types';

const handlersReducer = (state, action) => {
  switch (action.type) {
    case SET_LOADER:
      return {
        ...state,
        loading: true,
      };
    case REMOVE_LOADER:
      return {
        ...state,
        loading: false,
      };
    case SET_MESSAGE:
      return {
        ...state,
        message: action.payload,
        is_message: true,
      };
    case REMOVE_MESSAGE:
      return {
        ...state,
        is_message: false,
      };
    case SET_IS_CLICKED:
      return {
        ...state,
        is_clicked: action.payload,
      };
    case SET_IMAGE_SRC:
      return {
        ...state,
        image_src: action.payload,
      };
    case SET_PDF_SRC:
      return {
        ...state,
        pdf_src: action.payload,
      };
    default:
      return state;
  }
};

export default handlersReducer;
