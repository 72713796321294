import React, { useReducer, createContext } from 'react';
import axios from 'axios';

import ehReducer from './ehReducer';
import {
  GET_KURSE,
  SET_TN,
  CLEAR_EH,
  SET_ONE_KURS,
  GET_TN,
  GET_ONE_TN,
  SET_ONE_TN,
  GET_WARTELISTE,
  GET_PARTNER,
  GET_ONE_PARTNER,
  SET_ONE_PARTNER,
  SET_LOADING,
  GET_ONE_KURS,
  DELETE_PARTNER,
} from '../types';

export const ehContext = createContext();
const { Provider } = ehContext;

const print = (err) => {
  if (err.response.data) {
    console.log(err.response.data);
  } else {
    console.log(err);
  }
};
const EhState = (props) => {
  const initialState = {
    warteliste: [],
    kurse: [],
    one_kurs: null,
    tn: [],
    one_tn: null,
    partner: [],
    one_partner: null,
    rest_plaetze: 0,
    loading: false,
  };
  const [state, dispatch] = useReducer(ehReducer, initialState);

  const set_loading = async (bool) => {
    dispatch({
      type: SET_LOADING,
      payload: bool,
    });
  };
  const get_kurse = async () => {
    try {
      const res = await axios.get(`/kurse_api`);
      dispatch({
        type: GET_KURSE,
        payload: res.data.result,
      });
    } catch (err) {
      print(err);
    }
  };

  const get_one_kurs = async (id) => {
    try {
      const res_kurs = await axios.get(`/kurse_api?id=${id}`);
      // const res_tn = await axios.get(`/teilnehmer_api?kurs_id=${id}`);
      if (Array.isArray(res_kurs.data.result)) {
        console.log('id existiert kein kurs');
        return;
      }
      const payload = {
        kurs: res_kurs.data.result,
        rest_plaetze: res_kurs.data.rest_plaetze,
      };
      dispatch({
        type: GET_ONE_KURS,
        payload: payload,
      });
    } catch (err) {
      print(err);
    }
  };

  const get_warteliste = async () => {
    try {
      const res = await axios.get('/kurse_api?warteliste=ja');
      dispatch({
        type: GET_WARTELISTE,
        payload: res.data.result,
      });
    } catch (err) {
      print(err);
    }
  };

  const get_tn = async (type, id) => {
    var res = [];
    if (type === 'kurs_id') {
      res = await axios.get(`/teilnehmer_api?kurs_id=${id}`);
    } else {
      res = await axios.get(`/teilnehmer_api`);
    }
    console.log(res.data);
    dispatch({
      type: GET_TN,
      payload: res.data,
    });
  };

  const get_one_tn = async (id) => {
    var res = await axios.get(`/teilnehmer_api?id=${id}`);

    dispatch({
      type: GET_ONE_TN,
      payload: res.data.result,
    });
  };
  // Partner
  const get_partner = async () => {
    try {
      const res = await axios.get(`/users_api`);

      dispatch({
        type: GET_PARTNER,
        payload: res.data.result,
      });
    } catch (err) {
      print(err);
    }
  };

  const get_one_partner = async (id) => {
    const res_partner = await axios.get(`/users_api?id=${id}`);
    const payload = {
      partner: res_partner.data.result,
    };
    dispatch({
      type: GET_ONE_PARTNER,
      payload: payload,
    });
  };

  const delete_partner = async (id) => {
    try {
      const data = {
        status: 'inactive',
      };
      const res = await axios.put(`/users_api/new_status?id=${id}`, data);
      console.log(res.data);
      dispatch({
        type: DELETE_PARTNER,
        payload: id,
      });
    } catch (err) {
      print(err);
    }
  };

  const set_one_partner = (id) => {
    let one_tn = null;
    if (id !== null) {
      one_tn = state.partner.find((x) => x.id === id);
    }
    dispatch({
      type: SET_ONE_PARTNER,
      payload: one_tn,
    });
  };
  const set_tn = async (tn) => {
    dispatch({
      type: SET_TN,
      payload: tn,
    });
  };

  const set_one_kurs = (id, type = 'regular') => {
    let kurs = null;
    if (id !== null) {
      if (type === 'warteliste') {
        kurs = state.warteliste.find((x) => x.id === id);
      } else {
        kurs = state.kurse.find((x) => x.id === id);
      }
    }
    dispatch({
      type: SET_ONE_KURS,
      payload: kurs,
    });
  };

  const set_one_tn = (id) => {
    let one_tn = null;
    if (id !== null) {
      one_tn = state.tn.find((x) => x.id === id);
    }
    dispatch({
      type: SET_ONE_TN,
      payload: one_tn,
    });
  };
  const clear_eh = () => {
    dispatch({
      type: CLEAR_EH,
      payload: null,
    });
  };

  return (
    <Provider
      value={{
        warteliste: state.warteliste,
        kurse: state.kurse,
        one_kurs: state.one_kurs,
        tn: state.tn,
        one_tn: state.one_tn,
        partner: state.partner,
        one_partner: state.one_partner,
        loading: state.loading,
        rest_plaetze: state.rest_plaetze,
        get_kurse,
        get_warteliste,
        clear_eh,
        set_one_kurs,
        set_one_tn,
        get_tn,
        get_one_tn,
        set_tn,
        get_partner,
        set_one_partner,
        delete_partner,
        get_one_kurs,
        set_loading,
        get_one_partner,
      }}
    >
      {props.children}
    </Provider>
  );
};

export default EhState;
