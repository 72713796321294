import React, { useEffect, useState, useContext, Fragment } from 'react';
import axios from 'axios';
import { handlersContext } from '../../context/handlers/HandlersState';
import { useHistory } from 'react-router-dom';
import WebsiteShowcase from './WebsiteShowcase';
import WebsiteFooter from './WebsiteFooter';

const WebsiteErfolg = ({ id }) => {
  const history = useHistory();
  const handlers_Context = useContext(handlersContext);
  const { set_message } = handlers_Context;
  const [oneTn, setOneTn] = useState(null);
  const [text, setText] = useState(null);
  useEffect(() => {
    // check if already payed...
    const load_db = async () => {
      try {
        const res_tn = await axios.get(`/website_api/get_one_tn?id=${id}`);
        const result_tn = res_tn.data.result;
        console.log(result_tn);
        if (result_tn.bezahlt === 0) {
          history.push('/eh/bezahlung/' + id);
        }
        var res_text = null;
        if (result_tn.date === 'Warteliste') {
          res_text = await axios.get(
            `/website_api/get_text?type=buchen/erfolg_warteliste`
          );
          setText(res_text.data.text);
        } else {
          res_text = await axios.get(
            `/website_api/get_text?type=buchen/erfolg`
          );
          setText(res_text.data.text);
        }
        // console.log(result_tn);
        // return;
        setOneTn(result_tn);
      } catch (error) {
        console.log(error.response.data);
        set_message(error.response.data.error_text);
      }
    };

    load_db();
    // eslint-disable-next-line
  }, []);
  return (
    <Fragment>
      <section className="website-erfolg">
        <div className="container pt-4">
          {oneTn !== null && text !== null && (
            <Fragment>
              <h2 className="text-center">
                Vielen Dank {oneTn.info.vorname} {oneTn.info.nachname}!
                <br /> Du hast erfolgreich den folgenden Kurs bezahlt:
              </h2>
              <WebsiteShowcase
                id={oneTn.kurs_id}
                show_open={false}
                is_tn={oneTn.id}
              />

              <div className="website-erfolg-text">
                <div dangerouslySetInnerHTML={{ __html: text }}></div>
              </div>
            </Fragment>
          )}
        </div>
      </section>
      <WebsiteFooter />
    </Fragment>
  );
};

export default WebsiteErfolg;
