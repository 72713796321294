import React, { useReducer, createContext, useEffect } from 'react';
import axios from 'axios';
import authReducer from './authReducer';
import { LOGIN_SUCCESS, USER_LOGGED, USER_LOGOUT } from '../types';

export const authContext = createContext();
const { Provider } = authContext;

const AuthState = (props) => {
  const initialState = {
    user_id: null,
    user_status: null,
    is_auth: false,
    error: null,
    is_loading: true,
  };
  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(
    () => loadUser(),
    // eslint-disable-next-line
    []
  );

  const loadUser = async () => {
    try {
      const res = await axios.get(`/users_api/load_user`);
      dispatch({ type: USER_LOGGED, payload: res.data });
    } catch (error) {
      dispatch({ type: USER_LOGOUT, payload: null });
    }
  };
  const login = async (data) => {
    try {
      const res = await axios.post(`/users_api/login`, data);
      dispatch({ type: LOGIN_SUCCESS, payload: res.data });
      return true;
    } catch (error) {
      return false;
    }
  };

  const logout = async () => {
    try {
      await axios.get(`/users_api/logout`);
      dispatch({ type: USER_LOGOUT, payload: null });
    } catch (error) {
      console.log('erroro noch zu behebne');
    }
  };
  return (
    <Provider
      value={{
        user_id: state.user_id,
        user_status: state.user_status,
        is_auth: state.is_auth,
        loading: state.loading,
        error: state.error,
        is_loading: state.is_loading,
        login,
        logout,
        loadUser,
      }}
    >
      {props.children}
    </Provider>
  );
};

export default AuthState;
