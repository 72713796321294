import React, { useContext, useEffect, useState, Fragment } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { handlersContext } from '../../context/handlers/HandlersState';
import { useHistory } from 'react-router-dom';
import Calendar from './Calendar';
import KursForm from './KursForm';
import Dropdown from '../stuff/Dropdown';
import ConfirmStep from '../handlers/ConfirmStep';
import KursWeitereTermine from './KursWeitereTermine';

const KursAdmin = ({ action, id }) => {
  const handlers_Context = useContext(handlersContext);
  const {
    set_loader,
    set_message,
    remove_loader,
    wait,
    is_clicked,
    set_is_clicked,
  } = handlers_Context;
  let history = useHistory();
  const [state, setState] = useState({
    stadt: false,
    date: false,
    start: false,
    end: false,
    street_name: false,
    street: false,
    plz: false,
    city: false,
    price: false,
    n_participant: false,
    dozent_id: 0,
    weitere_termine: [],
  });

  const [dozents, setDozents] = useState([]);

  const [error, setError] = useState(false);
  const [error_text, setError_Text] = useState('');
  const [stadt, setStadt] = useState(false);
  const [tn_statistik, setTn_Statistik] = useState({
    bezahlt: null,
    gebucht: null,
  });

  const [staedte, setStaedte] = useState([]);
  const load_all_dozenten = async () => {
    try {
      const res = await axios.get(`/users_api`);
      const result = res.data.result;
      var output = [{ id: 0, name: '------' }];
      result.forEach((user) => {
        if (user.status === 'dozent') {
          output.push(user);
        }
      });
      return output;
    } catch (error) {
      console.log(error.response.data);
      set_message(error.response.data.error_text);
    }
  };

  const [confirm_delete, setConfirm_Delete] = useState({
    question: '',
    confirm_text: 'Bestätigen',
    reject_text: 'Verwerfen',
    answer: false,
    active: false,
    type: 'delete_kurs',
  });
  const [weitere_termine, setWeitere_Termine] = useState({});

  useEffect(() => {
    const load_all_staedte = async () => {
      try {
        const res = await axios.get(`/kurse_api/staedte`);
        setStaedte(res.data.result);
      } catch (error) {
        console.log(error.response.data);
        set_message(error.response.data.error_text);
      }
    };
    if (action === 'create') {
      load_all_staedte();
    }
    // eslint-disable-next-line
  }, []);
  // load last kurs in create
  useEffect(() => {
    const load_last_kurs_created = async () => {
      if (stadt !== false) {
        try {
          const res_kurs = await axios.get(
            `/kurse_api/last-created?stadt=${stadt}`
          );
          const result = res_kurs.data.result;
          if (result === null) {
            setState({
              ...state,
              stadt: stadt,
            });
          } else {
            setState({
              ...state,
              stadt: stadt,
              start: result.info.start,
              end: result.info.end,
              city: result.info.city,
              n_participant: result.n_participant,
              plz: result.info.plz,
              price: result.price,
              street: result.info.street,
              street_name: result.info.street_name,
            });
          }
        } catch (error) {
          console.log(error.response.data);
          set_message(error.response.data.error_text);
        }
      }
    };
    if (action === 'create') {
      load_last_kurs_created();
    }
    // eslint-disable-next-line
  }, [stadt]);
  // load kurs in edit
  useEffect(() => {
    const load_kurs = async () => {
      try {
        if (action === 'edit') {
          const res_kurs = await axios.get(`/kurse_api?id=${id}`);
          const dozenten_db = await load_all_dozenten();

          const result = res_kurs.data.result;
          if (result === null) {
            return;
          } else {
            var dozent_id = 0;
            dozenten_db.forEach((d) => {
              if (d.name === result.dozent_name) {
                dozent_id = d.id;
              }
            });
            setState({
              ...state,
              date: result.date,
              stadt: result.stadt,
              start: result.info.start,
              end: result.info.end,
              city: result.info.city,
              n_participant: result.n_participant,
              dozent_id: dozent_id,
              plz: result.info.plz,
              price: result.price,
              street: result.info.street,
              street_name: result.info.street_name,
            });
            setConfirm_Delete({
              ...confirm_delete,
              question: `Möchtest du den Kurs am <span class="red-text">${result.date} in ${result.stadt}</span> wirklich löschen ?`,
            });
            setTn_Statistik({
              bezahlt: result.bezahlt,
              gebucht: result.gebucht,
              open: result.open,
            });
            setDozents(dozenten_db);
          }
        } else {
          const dozenten_db = await load_all_dozenten();
          setDozents(dozenten_db);
        }
      } catch (error) {
        console.log(error.response.data);
        set_message(error.response.data.error_text);
        return;
      }
    };
    load_kurs();
    // eslint-disable-next-line
  }, []);

  const submit = async () => {
    if (is_clicked === true) {
      console.log('Double click');
      return;
    }
    set_is_clicked(true);
    setError(true);
    var valid = true;
    for (const prop in state) {
      if (
        (state[prop] === false || state[prop] === '') &&
        prop !== 'dozent_id'
      ) {
        valid = false;
      }
    }
    if (!valid) {
      setError_Text('Bitte fülle alle Felder aus!');
      return set_is_clicked(false);
    } else {
      setError_Text('');
      set_loader();
      try {
        var res = null;
        if (action === 'create') {
          res = await axios.post(`/kurse_api`, state);
        } else if (action === 'edit') {
          res = await axios.put(`/kurse_api?id=${id}`, state);
        }
        console.log(res.data);
        await wait();
        remove_loader();
        set_is_clicked(false);
        history.goBack();
      } catch (err) {
        remove_loader();
        set_message(err.response.data.error_text);
        return set_is_clicked(false);
      }
    }
  };

  const delete_fct = async () => {
    if (is_clicked === true) {
      return;
    }
    set_is_clicked(true);
    set_loader();
    try {
      const res = await axios.put(`/kurse_api/inactive?id=${id}`, {});
      console.log(res.data);
      await wait();
      remove_loader();
      set_is_clicked(false);
      history.goBack();
    } catch (err) {
      remove_loader();
      set_message(err.response.data.error_text);
      console.log(err.response.data);
      return set_is_clicked(false);
    }
  };

  useEffect(() => {
    if (confirm_delete.answer === true) {
      delete_fct();
    }
    // eslint-disable-next-line
  }, [confirm_delete.answer]);

  useEffect(() => {
    var weitere_termine_arr = [];
    for (var prop in weitere_termine) {
      if (weitere_termine[prop] !== null && weitere_termine[prop] !== false) {
        weitere_termine_arr.push(weitere_termine[prop]);
      }
    }
    setState({
      ...state,
      weitere_termine: weitere_termine_arr,
    });
    // eslint-disable-next-line
  }, [weitere_termine]);

  return (
    <Fragment>
      <ConfirmStep confirm={confirm_delete} setConfirm={setConfirm_Delete} />
      <div className="kurs_admin py-2">
        <div className="container  admin_container">
          <div className="text-center pb-2">
            <button
              onClick={() => {
                history.goBack();
              }}
            >
              Zurück
            </button>
          </div>
          <h1 className="text-center py-2">
            {action === 'create'
              ? 'Neuen Kurs anlegen'
              : `Kurs am ${state.date} in ${state.stadt} bearbeiten`}
          </h1>
          {action === 'create' ? (
            <div className="stadt-container pb-4">
              <p style={{ fontSize: '1.6rem' }} className="pb-2">
                Stadt:
              </p>
              <Dropdown
                elements={staedte}
                choosen={stadt}
                setChoosen={setStadt}
                error={error}
              />
            </div>
          ) : (
            <Fragment>
              <div className="btn-container-3 pt-2 pb-5">
                <button
                  className="btn-red mr-1"
                  onClick={() => {
                    setConfirm_Delete({
                      ...confirm_delete,
                      active: true,
                    });
                  }}
                >
                  Löschen
                </button>
                <button
                  onClick={() => {
                    history.push('/fahrschule/kurs/' + id);
                  }}
                >
                  Teilnehmer
                </button>
              </div>
              <p
                style={{
                  fontSize: '1.6rem',
                }}
                className="pb-2"
              >
                {' '}
                Kurs in {state.stadt}{' '}
              </p>
            </Fragment>
          )}

          <div className="calendar-container">
            <p className="pb-2 termin-text">Termin:</p>
            <Calendar
              name={'date'}
              state={state}
              setState={setState}
              error={error}
            />
          </div>
          {action === 'create' && (
            <p
              className="pt-4 py-2"
              style={{
                fontSize: '1.6rem',
              }}
            >
              Weitere Termine:{'  '}
              <button
                className="btn-green"
                style={{
                  transform: 'translateY(-4px)',
                }}
                onClick={() => {
                  setWeitere_Termine({
                    ...weitere_termine,
                    [uuidv4()]: false,
                  });
                }}
              >
                Hinzufügen
              </button>
            </p>
          )}

          <KursWeitereTermine
            state={weitere_termine}
            setState={setWeitere_Termine}
          />
          <KursForm
            state={state}
            setState={setState}
            error={error}
            tn_statistik={tn_statistik}
            action={action}
            dozents={dozents}
            setDozents={setDozents}
            is_warteliste={false}
          />
          <div className="text-center py-4">
            <div
              className={
                error_text !== '' ? 'error-text pb-2' : 'error-text hide pb-2'
              }
            >
              {error_text}
            </div>

            <button onClick={submit} className="btn-green">
              {action === 'create' ? 'Erstellen' : 'Speichern'}
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default KursAdmin;
