import React, { Fragment } from 'react';
import GutscheineTable from './GutscheineTable';
import { useHistory } from 'react-router-dom';

const Gutscheine = () => {
  const history = useHistory();
  return (
    <Fragment>
      <h1 className="text-center pt-2">Gutscheine verwalten</h1>
      <div className="text-center pt-2">
        <button
          onClick={() => {
            history.push('/fahrschule/gutscheine/erstellen');
          }}
        >
          Neuen Gutschein erstellen
        </button>
      </div>
      <GutscheineTable />
    </Fragment>
  );
};

export default Gutscheine;
