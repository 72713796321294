import React, { useEffect, Fragment, useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
const GutscheineTable = () => {
  const history = useHistory();

  const [gutscheine, setGutscheine] = useState([]);
  useEffect(() => {
    const get_gutscheine = async () => {
      try {
        const res = await axios.get(`/gutscheine_api`);
        setGutscheine(res.data.result);
      } catch (err) {
        console.log(err.response.data);
        return;
      }
    };

    get_gutscheine();
  }, []);
  return (
    <Fragment>
      <div className="container">
        <div className="responsive-table">
          <table>
            <thead>
              <tr>
                <th>Nr</th>
                <th className="rp-col-1">Status</th>
                <th className="rp-col-2">Anzahl</th>
                <th className="rp-col-2">Rabatt</th>
                <th className="rp-col-2">offen</th>
                <th>Code</th>
                <th className="rp-col-1">Ablaufdatum</th>
                <th className="rp-col-1">Teilnehmer</th>
              </tr>
            </thead>
            <tbody>
              {gutscheine.map((one_gutschein, index) => {
                return (
                  <tr
                    onClick={() => {
                      history.push(
                        `/fahrschule/gutscheine/bearbeiten/${one_gutschein.id}`
                      );
                    }}
                    key={index}
                  >
                    <td className="text-center">{index + 1} </td>
                    <td className="rp-col-1">{one_gutschein.status} </td>
                    <td className="rp-col-2">{one_gutschein.anzahl}</td>
                    <td className="rp-col-2">
                      {one_gutschein.rabatt}{' '}
                      {one_gutschein.type === 'in_euro' ? '€' : '%'}
                    </td>
                    <td className="rp-col-2">
                      {one_gutschein.anzahl - one_gutschein.gebucht}
                    </td>
                    <td>{one_gutschein.code}</td>
                    <td className="rp-col-1">
                      {one_gutschein.expiration_date}
                    </td>
                    <td className="rp-col-1">
                      {one_gutschein.bezahlt} / {one_gutschein.gebucht}{' '}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <p className="text-center">bei Teilnehmer: (bezahlt/gebucht)</p>
        </div>
      </div>
    </Fragment>
  );
};

export default GutscheineTable;
