import React, { Fragment, useContext, useEffect, useRef } from 'react';
import { handlersContext } from '../../context/handlers/HandlersState';

const ImagePopUp = () => {
  const handlers_Context = useContext(handlersContext);
  const { image_src, set_image_src } = handlers_Context;
  const close = () => {
    set_image_src(null);
  };
  let image_ref = useRef();
  useEffect(() => {
    if (image_src === null) {
      return;
    }
    let outside_click = (e) => {
      if (!image_ref.current.contains(e.target)) {
        set_image_src(null);
      }
    };
    document.addEventListener('mousedown', outside_click);
    return () => {
      document.removeEventListener('mousedown', outside_click);
    };
  });
  return (
    <Fragment>
      {image_src !== null && (
        <div className="image-pop-up active">
          <img
            ref={image_ref}
            src={image_src}
            alt="Portfolio eines Fotografen"
          />
          <div className="close" onClick={close}>
            <i className="far fa-times-circle"></i>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default ImagePopUp;
