import React, { Fragment } from 'react';
import PartnerTable from './PartnerTable';
import { useHistory } from 'react-router-dom';

const Partner = () => {
  let history = useHistory();
  return (
    <Fragment>
      <h1 className="text-center pt-2">Partner verwalten</h1>
      <div className="text-center pt-2">
        <button
          onClick={() => {
            history.push('/fahrschule/neuer-partner');
          }}
        >
          Neuen Partner erstellen
        </button>
      </div>
      <PartnerTable />
    </Fragment>
  );
};

export default Partner;
