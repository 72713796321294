import axios from 'axios';
const on_load = () => {
  axios.defaults.withCredentials = true;
  axios.defaults.headers.post['Content-Type'] = 'application/json';
  axios.defaults.headers.put['Content-Type'] = 'application/json';
  // axios.defaults.baseURL = 'http://localhost:85/aufbauseminar/public';
  return true;
};

export default on_load;
