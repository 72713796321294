import React, { useReducer, createContext } from 'react';
import handlersReducer from './handlersReducer';
import {
  SET_LOADER,
  SET_MESSAGE,
  REMOVE_LOADER,
  REMOVE_MESSAGE,
  SET_IS_CLICKED,
  SET_IMAGE_SRC,
  SET_PDF_SRC,
} from '../types';

export const handlersContext = createContext();
const { Provider } = handlersContext;

const HandlersState = (props) => {
  const initialState = {
    is_message: false,
    message: '',
    loading: false,
    is_clicked: false,
    image_src: null,
    pdf_src: null,
  };
  const [state, dispatch] = useReducer(handlersReducer, initialState);
  const set_loader = () => dispatch({ type: SET_LOADER });
  const remove_loader = () => dispatch({ type: REMOVE_LOADER });

  const set_message = (text) => {
    dispatch({ type: SET_MESSAGE, payload: text });
  };
  const remove_message = (text) => {
    dispatch({ type: REMOVE_MESSAGE, payload: '' });
  };

  const set_is_clicked = (bool) =>
    dispatch({ type: SET_IS_CLICKED, payload: bool });

  const set_image_src = (src) => {
    dispatch({ type: SET_IMAGE_SRC, payload: src });
  };
  const set_pdf_src = (src) => {
    dispatch({ type: SET_PDF_SRC, payload: src });
  };
  const wait = (time = 2000) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve();
      }, time);
    });
  };

  return (
    <Provider
      value={{
        is_message: state.is_message,
        message: state.message,
        loading: state.loading,
        is_clicked: state.is_clicked,
        image_src: state.image_src,
        pdf_src: state.pdf_src,
        set_loader,
        remove_loader,
        set_message,
        remove_message,
        set_is_clicked,
        set_image_src,
        set_pdf_src,
        wait,
      }}
    >
      {props.children}
    </Provider>
  );
};

export default HandlersState;
