import React, { Fragment } from 'react';
import Dropdown from '../stuff/Dropdown';
import Checks from '../../utils/Checks';
const TeilnehmerForm = ({ state, setState, error, action }) => {
  const set_anrede_fct = (val) => {
    setState({
      ...state,
      anrede: val,
    });
  };

  const onChange = (e) => {
    var { name, value } = e.target;
    if (value === '' && name !== 'notiz') {
      value = false;
    }
    setState({
      ...state,
      [name]: value,
    });
  };

  const onFocus = (e) => {
    e.target.parentNode.classList.remove('error');
    e.target.classList.remove('error');
  };

  const onBlur = (e) => {
    var { name, value } = e.target;
    var is_valid = true;
    if (name === 'email') {
      is_valid = Checks.email(value);
    } else if (name === 'phone') {
      is_valid = Checks.phone(value);
    } else if (name === 'plz') {
      is_valid = Checks.plz(value);
    } else if (name === 'birthday') {
      is_valid = onBlur_birthday(e);
    }
    if (is_valid === false) {
      e.target.parentNode.classList.add('error');
      e.target.classList.add('error');
      return;
    } else {
      e.target.parentNode.classList.remove('error');
      e.target.classList.remove('error');
      return;
    }
  };

  const onBlur_birthday = (e) => {
    var { value } = e.target;
    if (/\D\/$/.test(value)) value = value.substr(0, value.length - 3);
    let values = value.split('.').map(function (v) {
      return v.replace(/\D/g, '');
    });
    values[2] = year_helper(values[2]);
    let output = values.map(function (v, i) {
      return v.length === 2 && i < 2 ? v + '.' : v;
    });
    output = output.join('').substr(0, 10);
    setState({
      ...state,
      birthday: output,
    });
    return Checks.birthday(output);
  };

  const onChange_birthday = (e) => {
    // this.type = 'text';
    var { name, value } = e.target;
    if (/\D\/$/.test(value)) value = value.substr(0, value.length - 3);
    let values = value.split('.').map(function (v) {
      return v.replace(/\D/g, '');
    });

    if (values[0]) values[0] = birthday_helper(values[0], 31); // day check
    if (values[1]) values[1] = birthday_helper(values[1], 12); // month check
    // if (values[2]) {
    //   values[2] = birthday_helper(values[2], currentYear); // year check
    // }
    let output = values.map(function (v, i) {
      return v.length === 2 && i < 2 ? v + '.' : v;
    });
    output = output.join('').substr(0, 10);
    setState({
      ...state,
      [name]: output,
    });
  };

  const year_helper = (str) => {
    if (str.length === 2) {
      let num = parseInt(str);
      if (num > 20) {
        num = num + 1900;
      } else {
        num = num + 2000;
      }
      str = num.toString();
    }

    return str;
  };
  const birthday_helper = (str, max) => {
    if (str.charAt(0) !== '0' || str === '00') {
      let num = parseInt(str);
      if (isNaN(num) || num <= 0 || num > max) num = 0;
      if (
        num > parseInt(max.toString().charAt(0)) &&
        num.toString().length === 1
      ) {
        str = '0' + num;
      } else {
        str = num.toString();
      }
    }
    return str;
  };
  const onChange_checkbox = (e) => {
    var { name, checked } = e.target;
    if (checked) {
      setState({
        ...state,
        [name]: 1,
      });
    } else {
      setState({
        ...state,
        [name]: 0,
      });
    }
  };
  return (
    <Fragment>
      <div className="info-kasse">
        <div className="title">
          Persönliche Informationen
          <span className="error-top">Bitte fülle alle Felder aus!</span>
        </div>

        <div className="row">
          <div className="col-md-4 input-container">
            <p className="pb-1">Anrede</p>
            <Dropdown
              elements={['Frau', 'Herr', 'Divers']}
              choosen={state.anrede}
              setChoosen={set_anrede_fct}
              error={error}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="input-container">
              <p>Vorname</p>
              <input
                className={
                  error === true && state.vorname === false ? 'error' : ''
                }
                onFocus={onFocus}
                onChange={onChange}
                type="text"
                name="vorname"
                value={!state.vorname ? '' : state.vorname}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="input-container">
              <p>Nachname</p>
              <input
                className={
                  error === true && state.nachname === false ? 'error' : ''
                }
                onFocus={onFocus}
                onChange={onChange}
                type="text"
                name="nachname"
                value={!state.nachname ? '' : state.nachname}
              />
            </div>
          </div>
          <div className="col-12">
            <div className="input-container">
              <p>Straße & Hausnummer</p>
              <input
                className={
                  error === true && state.street === false ? 'error' : ''
                }
                onFocus={onFocus}
                onChange={onChange}
                type="text"
                name="street"
                value={!state.street ? '' : state.street}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="input-container">
              <p>Postleitzahl</p>
              <input
                className={error === true && state.plz === false ? 'error' : ''}
                onFocus={onFocus}
                onChange={onChange}
                onBlur={onBlur}
                type="text"
                name="plz"
                value={!state.plz ? '' : state.plz}
              />
            </div>
          </div>
          <div className="col-md-8">
            <div className="input-container">
              <p>Stadt</p>
              <input
                className={
                  error === true && state.city === false ? 'error' : ''
                }
                onFocus={onFocus}
                onChange={onChange}
                type="text"
                name="city"
                value={!state.city ? '' : state.city}
              />
            </div>
          </div>
          <div className="col-lg-5 col-md-6">
            <div className="input-container">
              <p>E-Mail-Adresse</p>
              <input
                className={
                  error === true && state.email === false ? 'error' : ''
                }
                onFocus={onFocus}
                onChange={onChange}
                onBlur={onBlur}
                type="text"
                name="email"
                value={!state.email ? '' : state.email}
              />
              <small>Bitte wähle eine gültige E-Mail-Adresse</small>
            </div>
          </div>
          <div className="col-lg-2 col-md-3">
            <div className="input-container">
              <p>Geburtstag</p>
              <input
                className={
                  error === true && state.birthday === false
                    ? 'error birthday-input'
                    : 'birthday-input'
                }
                onFocus={onFocus}
                onChange={onChange_birthday}
                onBlur={onBlur}
                type="text"
                name="birthday"
                placeholder="TT.MM.JJJJ"
                value={!state.birthday ? '' : state.birthday}
              />
            </div>
          </div>
          <div className="col-lg-5 col-md-6">
            <div className="input-container">
              <p>Telefon</p>
              <input
                className={
                  error === true && state.phone === false ? 'error' : ''
                }
                onFocus={onFocus}
                onChange={onChange}
                onBlur={onBlur}
                type="text"
                name="phone"
                value={!state.phone ? '' : state.phone}
              />
              <small>Bitte wähle eine gültige Telefonnummer</small>
            </div>
          </div>

          <div className="col-lg-5 col-md-6">
            <div className="input-container">
              <p>Notiz</p>
              <input
                onFocus={onFocus}
                onChange={onChange}
                type="text"
                name="notiz"
                value={!state.notiz ? '' : state.notiz}
              />
            </div>
          </div>
          {action === 'create' && (
            <div className="col-12 bezahlt-container py-4">
              <div className="round">
                <input
                  type="checkbox"
                  id="checkbox-payed"
                  className="mr-2"
                  name="bezahlt"
                  checked={state.bezahlt === 1 ? true : false}
                  onChange={onChange_checkbox}
                />
                <label htmlFor="checkbox-payed"></label>
              </div>
              <p className="ml-4">Teilnehmer hat bereits bezahlt.</p>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default TeilnehmerForm;
