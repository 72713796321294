import React, { Fragment, useEffect } from 'react';
import axios from 'axios';

const WebsiteCaptchaGutschein = ({
  submit,
  error_text,
  error,
  setState,
  state,
  new_captcha,
  captcha_src,
  setCaptcha_Src,
}) => {
  const onChange_law = (e) => {
    var { name, checked } = e.target;
    if (checked) {
      setState({
        ...state,
        [name]: 1,
      });
    } else {
      setState({
        ...state,
        [name]: 0,
      });
    }
  };
  const onChange = (e) => {
    var { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };
  useEffect(() => {
    const get_captcha = async () => {
      try {
        const res = await axios.get(`/captcha_api/create`);
        setCaptcha_Src(res.data.display);
        setState({
          ...state,
          captcha_crypted: res.data.crypted,
        });
      } catch (err) {
        console.log(err.response.data);
      }
    };
    get_captcha();
    // eslint-disable-next-line
  }, []);

  const onFocus = (e) => {
    e.target.parentNode.classList.remove('error');
    e.target.classList.remove('error');
    if (e.target.name === 'captcha') {
      e.target.parentNode.parentNode.classList.remove('error');
    }
  };
  return (
    <Fragment>
      <div className="info-kasse captcha-gutschein">
        <div className="sub-title">Gutscheincode</div>
        <div className="input-container pt-2">
          <input
            name="gutschein"
            type="text"
            onChange={onChange}
            onFocus={onFocus}
            value={state.gutschein}
          />
        </div>
        <div className="sub-title pt-2">Sicherheitskontrolle</div>
        <p
          className="sub-text
       "
        >
          Bitte gebe die Zahlen unten ein
        </p>
        <div
          className={
            error === true && state.captcha === ''
              ? 'captcha-container error'
              : 'captcha-container'
          }
        >
          <div className="captcha_img_container">
            {captcha_src !== null && <img src={captcha_src} alt="" />}
          </div>
          <div className="captcha-input-container">
            <input
              name="captcha"
              type="text"
              onChange={onChange}
              onFocus={onFocus}
              value={state.captcha}
            />
            <i onClick={new_captcha} className="spinner fas fa-sync-alt"></i>
          </div>
          <small>Ungültige Sicherheitskontrolle!</small>
        </div>

        <div
          className={
            error === true && state.law === 0
              ? 'law-container pt-5 error'
              : 'law-container pt-5'
          }
        >
          <div className="round">
            <input
              type="checkbox"
              id="checkbox-law"
              className="mr-2"
              name="law"
              onChange={onChange_law}
            />
            <label htmlFor="checkbox-law"></label>
          </div>
          <p className="ml-3">
            Hiermit stimme ich den{' '}
            <span
              className="link"
              onClick={() => {
                window.open('/agb', '_blank');
              }}
            >
              AGB
            </span>{' '}
            und den{' '}
            <span
              className="link"
              onClick={() => {
                window.open('/datenschutz', '_blank');
              }}
            >
              Datenschutzbestimmungen
            </span>{' '}
            zu.
            <small>Bitte stimme zu!</small>
          </p>
        </div>
        <div className="text-center">
          <div className="text-center py-5">
            <div
              className={
                error_text !== '' ? 'error-text pb-2' : 'error-text hide pb-2'
              }
            >
              {error_text}
            </div>
            <button onClick={submit}>zahlungspflichtig bestellen</button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default WebsiteCaptchaGutschein;
