import React, { Fragment } from 'react';
import Dropdown from '../stuff/Dropdown';
const KursForm = ({
  state,
  setState,
  error,
  tn_statistik,
  action,
  dozents,
  setDozents,
  is_warteliste,
}) => {
  const onChange = (e) => {
    var { name, value } = e.target;
    if (name === 'price' || name === 'n_participant') {
      value = value.replace(/,/g, '.');

      var input = Number(value);
      if (isNaN(input)) {
        return;
      } else {
        if (name === 'n_participant') {
          value = String(Math.floor(value));
        } else {
          value = value.replace(/\./g, ',');
        }
      }
    }
    if (value === '') {
      value = false;
    }
    setState({
      ...state,
      [name]: value,
    });
  };

  const set_dozent_elemnts = () => {
    var output = [];

    dozents.forEach((dozent) => {
      output.push(dozent.name);
    });
    return output;
  };

  const set_choosen_dozent = () => {
    var output = '';
    dozents.forEach((dozent) => {
      if (dozent.id === state.dozent_id) {
        output = dozent.name;
      }
    });
    return output;
  };

  const set_set_choosen_dozent = (el) => {
    var output = '';
    dozents.forEach((dozent) => {
      if (el === dozent.name) {
        setState({
          ...state,
          dozent_id: dozent.id,
        });
      }
    });
    return output;
  };
  const onFocus = (e) => {
    e.target.classList.remove('error');
  };

  return (
    <Fragment>
      <div className="input-container time-container pt-2">
        {state.hasOwnProperty('start') && (
          <div className="row ">
            <p className="col-12 pb-2">Uhrzeit:</p>
            <div className="col-sm-6">
              <span className="pr-1">Von:</span>
              <input
                className={
                  error === true && state.start === false ? 'error' : ''
                }
                onFocus={onFocus}
                onChange={onChange}
                name="start"
                type="text"
                value={!state.start ? '' : state.start}
              />
              <span className="pl-2">Uhr</span>
            </div>
            <div className="col-sm-6">
              <span className="pr-2">Bis:</span>
              <input
                className={error === true && state.end === false ? 'error' : ''}
                onFocus={onFocus}
                onChange={onChange}
                name="end"
                type="text"
                value={!state.end ? '' : state.end}
              />
              <span className="pl-2">Uhr</span>
            </div>
          </div>
        )}
      </div>
      <div className="input-container street-container pt-5">
        <div className="row">
          <div className="col-12 pt-2">
            <p>Adressenbezeichnung:</p>
            <input
              className={
                error === true && state.street_name === false ? 'error' : ''
              }
              onFocus={onFocus}
              onChange={onChange}
              name="street_name"
              type="text"
              value={!state.street_name ? '' : state.street_name}
            />
          </div>
          <div className="col-12 pt-2">
            <p>Straße:</p>
            <input
              className={
                error === true && state.street === false ? 'error' : ''
              }
              onFocus={onFocus}
              onChange={onChange}
              name="street"
              type="text"
              value={!state.street ? '' : state.street}
            />
          </div>
          <div className="col-12 pt-2">
            <p>Postleitzahl:</p>
            <input
              className={error === true && state.plz === false ? 'error' : ''}
              onFocus={onFocus}
              onChange={onChange}
              name="plz"
              type="text"
              value={!state.plz ? '' : state.plz}
            />
          </div>
          <div className="col-12 pt-2">
            <p>Stadt:</p>
            <input
              className={error === true && state.city === false ? 'error' : ''}
              onFocus={onFocus}
              onChange={onChange}
              name="city"
              type="text"
              value={!state.city ? '' : state.city}
            />
          </div>
          {is_warteliste === false && (
            <div className="col-12 pt-2">
              <p>Kursleitung: (optional)</p>
              <Dropdown
                elements={set_dozent_elemnts()}
                choosen={set_choosen_dozent()}
                setChoosen={set_set_choosen_dozent}
                error={error}
                size={'lg'}
              />
            </div>
          )}
        </div>
      </div>
      <div className="input-container price-container pt-5">
        <div className="row">
          <div className="col-12 pt-2">
            <p>Preis:</p>
            <input
              className={error === true && state.price === false ? 'error' : ''}
              onFocus={onFocus}
              onChange={onChange}
              name="price"
              type="text"
              value={!state.price ? '' : state.price}
            />
            <span className="pl-2">€</span>
          </div>
          <div className="col-12 pt-2">
            <p>Teilnehmeranzahl:</p>
            {action === 'edit' && (
              <p className="tn-statistik">
                bezahlt:{tn_statistik.bezahlt} gebucht:{tn_statistik.gebucht}{' '}
                offen:{tn_statistik.open}
              </p>
            )}

            <input
              className={
                error === true && state.n_participant === false ? 'error' : ''
              }
              onFocus={onFocus}
              onChange={onChange}
              name="n_participant"
              type="text"
              value={!state.n_participant ? '' : state.n_participant}
            />
            <small className="n_participant-error"> Bitte wähle ein Zahl</small>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default KursForm;
