import React, { useEffect, useState, useContext, Fragment } from 'react';
import axios from 'axios';
import { handlersContext } from '../../context/handlers/HandlersState';
import WebsiteShowcase from './WebsiteShowcase';
import WebsiteBezahlungAccordion from './WebsiteBezahlungAccordion';
import WebsiteFooter from './WebsiteFooter';
import { useHistory } from 'react-router-dom';

const WebsiteBezahlung = ({ id }) => {
  const history = useHistory();
  const handlers_Context = useContext(handlersContext);
  const { set_message, set_loader, remove_loader } = handlers_Context;
  const [text, setText] = useState(null);
  const [config, setConfig] = useState(null);
  const [bank, setBank] = useState(null);
  const [oneTn, setOneTn] = useState(null);

  useEffect(() => {
    // check if already payed...
    const load_db = async () => {
      try {
        const res = await axios.get(`/website_api/get_config_change`);
        const result = res.data.result;
        const res_bank = await axios.get(`/website_api/get_bank_info`);
        const res_tn = await axios.get(`/website_api/get_one_tn?id=${id}`);
        const result_tn = res_tn.data.result;
        if (result_tn.bezahlt === 1) {
          history.push('/eh/erfolg/' + id);
          // window.location.href = '/eh/erfolg/' + id;
        }
        setConfig(result);
        setBank({
          ...res_bank.data.result,
          betrag: `${result_tn.betrag}`,
          verwendungszweck: `${result_tn.info.nachname} - ${result_tn.info.birthday} - ${result_tn.stadt} ${result_tn.date}`,
        });

        setOneTn(result_tn);
        var res_text = null;
        if (result_tn.date === 'Warteliste') {
          res_text = await axios.get(
            `/website_api/get_text?type=buchen/bezahlung_warteliste`
          );
          setText(res_text.data.text);
        } else {
          res_text = await axios.get(
            `/website_api/get_text?type=buchen/bezahlung`
          );
          setText(res_text.data.text);
        }
      } catch (error) {
        console.log(error.response.data);
        set_message(error.response.data.error_text);
      }
    };
    load_db();
    // eslint-disable-next-line
  }, []);

  const to_payment = async (type, accordion_type) => {
    try {
      set_loader();
      const res = await axios.get(
        `/website_api/payment_link?tn_id=${id}&type=${type}&accordion_type=${accordion_type}`
      );
      window.location.href = res.data.redirect;
    } catch (error) {
      console.log(error.response.data);
      set_message(error.response.data.error_text);
      remove_loader();
    }
  };
  const payments = {
    novalnet: {
      title: 'Onlinezahlung',
      type: 'novalnet',
      img_src: '',
    },
    paypal: {
      title: 'PayPal',
      type: 'paypal',
      img_src: '/img/bezahlung/paypal.jpg',
    },
    creditcard: {
      title: 'Kreditkarte',
      type: 'creditcard',
      img_src:
        'https://www.novalnet.de/images/140904_Novalnet_kombinierteLogos_04_quer.png',
    },
    klarna: {
      title: 'Sofortüberweisung',
      type: 'klarna',
      img_src: '/img/bezahlung/klarna.png',
    },
    vorkasse: {
      title: 'Banküberweisung',
      type: 'vorkasse',
      img_src: null,
    },
    sepa: {
      title: 'SEPA Lastschrift',
      type: 'sepa',
      img_src: '/img/bezahlung/SepaLogoDe.jpg',
    },
  };
  return (
    <Fragment>
      <section className="website-bezahlung">
        <div className="container pt-4">
          {oneTn !== null && config !== null && text !== null && (
            <Fragment>
              <h2 className="text-center">
                Vielen Dank {oneTn.info.vorname} {oneTn.info.nachname}!
                <br /> Du hast erfolgreich den folgenden Kurs gebucht:
              </h2>
              <WebsiteShowcase
                id={oneTn.kurs_id}
                show_open={false}
                is_tn={oneTn.id}
              />
              <div className="second-part">
                <div className="zahluebergang">
                  <p className="sub-title">Bezahlung</p>
                  <div dangerouslySetInnerHTML={{ __html: text }}></div>
                  <p className="betrag">Gesamtbetrag: {oneTn.betrag} €</p>
                </div>

                <WebsiteBezahlungAccordion
                  to_payment={to_payment}
                  bank={bank}
                  config={config}
                  payments={payments}
                />
              </div>
            </Fragment>
          )}
        </div>
      </section>
      <WebsiteFooter />
    </Fragment>
  );
};

export default WebsiteBezahlung;
