import React, { Fragment, useContext, useRef, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { authContext } from '../../context/auth/AuthState';
import { ehContext } from '../../context/eh/EhState';

const Navbar = () => {
  const auth_Context = useContext(authContext);
  const { is_auth, logout, user_status } = auth_Context;
  const eh_Context = useContext(ehContext);
  const { clear_eh } = eh_Context;
  const nav_links = document.querySelectorAll('.z-nav .z-nav-page');
  const speed = 0.65;
  useEffect(() => {
    if (pages.current.dummy === '') {
      return;
    }
    const handleResize = () => {
      pages.current.style.animation = `navSlideOut ${speed}s ease backwards`;
      pages.current.dummy = '';
      burger.current.classList.remove('z-toggle');
      nav_links.forEach((link, index) => {
        link.style.animation = '';
      });
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line
  }, []);
  const pages = useRef();
  const burger = useRef();
  const onLogout = (e) => {
    e.preventDefault();
    logout();
    clear_eh();
  };

  const animation = () => {
    if (pages.current.dummy) {
      pages.current.style.animation = `navSlideOut ${speed}s ease backwards`;
      pages.current.dummy = '';
      burger.current.classList.remove('z-toggle');
    } else {
      pages.current.style.animation = `navSlideIn ${speed}s ease forwards`;
      pages.current.dummy = 'x';
      burger.current.classList.add('z-toggle');
    }

    nav_links.forEach((link, index) => {
      var speed_pro = index / 8 + speed - 0.35;
      if (link.style.animation) {
        link.style.animation = '';
      } else {
        link.style.animation = `navLinkFade 0.5s ease forwards ${speed_pro}s`;
      }
    });
  };

  const close_nav = (e) => {
    pages.current.style.animation = `navSlideOut ${speed}s ease backwards`;
    pages.current.dummy = '';
    burger.current.classList.remove('z-toggle');
    nav_links.forEach((link, index) => {
      link.style.animation = '';
    });
  };
  const go_back = async (e) => {
    e.preventDefault();
    close_nav(e);
    try {
      const res = await axios.get(`/admin_api/go_back`);
      console.log(res.data);
      window.location.href = res.data.redirect;
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <nav className="z-nav">
      <div className="z-logo-container logo_navbar_link">EH Admin</div>
      <div ref={pages} className="z-nav-pages">
        {!is_auth && (
          <Fragment>
            <div className="z-nav-page">
              <Link onClick={close_nav} to="/fahrschule/login">
                Login
              </Link>
            </div>
            <div className="z-nav-page">
              <Link onClick={go_back} to="/fahrschule/">
                Zurück
              </Link>
            </div>
          </Fragment>
        )}

        {is_auth && (
          <Fragment>
            <div className="z-nav-page">
              <Link onClick={close_nav} to="/fahrschule/suche">
                <i className="fa fa-search" aria-hidden="true"></i>
              </Link>
            </div>
            <div className="z-nav-page">
              <Link onClick={close_nav} to="/fahrschule/">
                Kurse
              </Link>
            </div>
            {user_status === 'admin' && (
              <Fragment>
                <div className="z-nav-page">
                  <Link onClick={close_nav} to="/fahrschule/partner">
                    Partner
                  </Link>
                </div>
                <div className="z-nav-page">
                  <Link onClick={close_nav} to="/fahrschule/gutscheine">
                    Gutscheine
                  </Link>
                </div>
                <div className="z-nav-page">
                  <Link onClick={close_nav} to="/fahrschule/fahrschule-admin">
                    Admin
                  </Link>
                </div>
              </Fragment>
            )}
            <div className="z-nav-page">
              <Link onClick={onLogout} to="/fahrschule/login">
                Logout
              </Link>
            </div>
          </Fragment>
        )}
      </div>
      <div ref={burger} className="z-burger" onClick={animation}>
        <div className="line1"></div>
        <div className="line2"></div>
        <div className="line3"></div>
      </div>
    </nav>
  );
};

export default Navbar;
