import React, { Fragment, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import TnTable from '../tn/TnTable';
import { ehContext } from '../../context/eh/EhState';
import BackButton from '../layout/BackButton';

const TnFromPartner = ({ id }) => {
  const [pagination, setPagination] = useState({
    page: 1,
    count: false,
    tn_pro_seite: false,
    type: 0,
    direction: 'DESC',
    name: '',
    date: '',
  });

  const eh_Context = useContext(ehContext);
  const { get_one_partner, set_tn, one_partner } = eh_Context;
  useEffect(() => {
    const get_tn = async () => {
      try {
        const table_info = await axios.get(
          `/teilnehmer_api/table_info?partner_id=${id}`
        );
        const res_kurs = await axios.get(`/teilnehmer_api?partner_id=${id}`);
        const result = res_kurs.data.result;
        if (result === null) {
          return;
        } else {
          set_tn(result);
          setPagination({
            ...pagination,
            count: table_info.data.count,
            tn_pro_seite: table_info.data.tn_pro_seite,
          });
        }
      } catch (error) {
        console.log(error.response.data);
        return;
      }
    };
    get_one_partner(id);
    get_tn();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const get_tn = async () => {
      try {
        const res_kurs = await axios.get(
          `/teilnehmer_api?partner_id=${id}&page=${pagination.page}&sort_type=${pagination.type}&sort_direction=${pagination.direction}`
        );
        const result = res_kurs.data.result;
        if (result === null) {
          return;
        } else {
          set_tn(result);
        }
      } catch (error) {
        console.log(error.response.data);
        return;
      }
    };

    get_tn();
    // eslint-disable-next-line
  }, [pagination]);
  return (
    <div>
      {one_partner !== null && (
        <Fragment>
          <BackButton padding={'py-2'} />
          <div className="text-center ">
            <h2>Partner : {one_partner.name}</h2>

            <p className="partner-insgesamt pt-2">
              <span className="title">Insgesamt</span>
              <br /> bezahlt: <span> {one_partner.bezahlt}</span> gebucht:
              <span> {one_partner.gebucht}</span>
            </p>
          </div>
        </Fragment>
      )}

      <TnTable pagination={pagination} setPagination={setPagination} />
    </div>
  );
};

export default TnFromPartner;
