import React, { useContext } from 'react';
import { ehContext } from '../../context/eh/EhState';
import { authContext } from '../../context/auth/AuthState';

const WartelisteRow = ({ kurs, setBox }) => {
  const eh_Context = useContext(ehContext);
  const { set_one_kurs } = eh_Context;
  const auth_Context = useContext(authContext);
  const { user_status } = auth_Context;
  return (
    <tr
      className="one_kurs"
      onClick={() => {
        set_one_kurs(kurs.id, 'warteliste');
        setBox(true);
      }}
    >
      <td> {kurs.stadt}</td>
      {user_status === 'admin' ? (
        <td className="rp-col-2">
          {kurs.open} ( {kurs.bezahlt} / {kurs.gebucht} / {kurs.n_participant} )
        </td>
      ) : (
        <td className="rp-col-2">{kurs.open}</td>
      )}
    </tr>
  );
};

export default WartelisteRow;
