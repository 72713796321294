import React, { Fragment, useContext, useState } from 'react';
import axios from 'axios';
import { ehContext } from '../../context/eh/EhState';
import { useHistory } from 'react-router-dom';
import { handlersContext } from '../../context/handlers/HandlersState';
import { authContext } from '../../context/auth/AuthState';
const TnRow = ({ tn, index }) => {
  const auth_Context = useContext(authContext);
  const { user_status } = auth_Context;
  const eh_Context = useContext(ehContext);
  const { set_one_tn, set_tn } = eh_Context;
  const handlers_Context = useContext(handlersContext);
  const { set_message, set_loader, remove_loader } = handlers_Context;
  const history = useHistory();

  const { info, teilgenommen, bezahlt, kurs_id, user_id, id } = tn;
  const [local_bezahlt, setLocal_Bezahlt] = useState(bezahlt);
  const update_bezahlt = async () => {
    try {
      set_loader();
      var data_bezahlt = {};
      if (local_bezahlt === 1) {
        data_bezahlt.status = 0;
      } else {
        data_bezahlt.status = 1;
      }

      await axios.put(`/teilnehmer_api/payment?id=${id}`, data_bezahlt);
      remove_loader();
    } catch (err) {
      if (
        user_status === 'admin' &&
        err.response.data.error_type === 'kurs_full'
      ) {
        remove_loader();
        history.push('/fahrschule/umbuchen/nach-bezahlung/' + id);
        return;
      } else {
        console.log(err.response.data);
        set_message(err.response.data.error_text);
        remove_loader();
        return;
      }
    }
    if (local_bezahlt === 1) {
      setLocal_Bezahlt(0);
    } else {
      setLocal_Bezahlt(1);
    }
  };

  const [local_teilgenommen, setLocal_Teilgenommen] = useState(teilgenommen);
  const update_teilgenommen = async () => {
    try {
      var data_bezahlt = {};
      if (local_teilgenommen === 1) {
        data_bezahlt.status = 0;
      } else {
        data_bezahlt.status = 1;
      }

      await axios.put(`/teilnehmer_api/teilgenommen?id=${id}`, data_bezahlt);
    } catch (err) {
      console.log(err.response.data);
      set_message(err.response.data.error_text);
      return;
    }
    if (local_teilgenommen === 1) {
      setLocal_Teilgenommen(0);
    } else {
      setLocal_Teilgenommen(1);
    }
  };

  return (
    <Fragment>
      <tr
        className={local_bezahlt === 1 ? '' : 'not-payed'}
        onClick={(e) => {
          set_one_tn(tn.id);
          if (
            ['kurs', 'partner', 'bezahlt', 'teilgenommen'].includes(
              e.target.dataset.row
            )
          ) {
            return;
          } else {
            history.push(`/fahrschule/teilnehmer-bearbeiten/${tn.id}`);
          }
        }}
      >
        <td className="text-center">{index}</td>
        <td>
          {info.anrede} {info.vorname} {info.nachname}
        </td>
        <td className="rp-col-2">{info.birthday}</td>

        <td className="rp-col-lg">
          {info.city} {info.plz}
        </td>

        <td
          data-row="kurs"
          className="rp-col-3"
          onClick={() => {
            const current = window.location.pathname.split('/');
            if (current[2] !== 'kurs') {
              set_tn([]);
              history.push(`/fahrschule/kurs/${kurs_id}`);
            }
          }}
        >
          {tn.stadt} {tn.date}
        </td>
        <td
          className="rp-col-sm"
          data-row="partner"
          onClick={() => {
            const current = window.location.pathname.split('/');
            if (
              current[2] !== 'teilnehmer-partner' &&
              user_status === 'admin'
            ) {
              set_tn([]);
              history.push(`/fahrschule/teilnehmer-partner/${user_id}`);
            }
          }}
        >
          {tn.user_name}
        </td>
        <td className="rp-col-lg" data-column="created_at">
          {tn.created_at}
        </td>
        <td
          className="check-container rp-col-2"
          data-row="bezahlt"
          onClick={update_bezahlt}
        >
          {local_bezahlt === 1 ? <i className="fas fa-check"></i> : ''}
        </td>

        <td
          className="check-container rp-col-1"
          data-row="teilgenommen"
          onClick={update_teilgenommen}
        >
          {local_teilgenommen === 1 ? <i className="fas fa-check"></i> : ''}
        </td>
      </tr>
    </Fragment>
  );
};

export default TnRow;
