import React, {
  useContext,
  useState,
  useEffect,
  useRef,
  Fragment,
} from 'react';
import Checks from '../../utils/Checks';
import { useHistory } from 'react-router-dom';
import { authContext } from '../../context/auth/AuthState';
import { handlersContext } from '../../context/handlers/HandlersState';

const Login = (props) => {
  const [user, setUser] = useState({
    email: '',
    password: '',
    error_text: '',
  });
  let history = useHistory();
  const { email, password, error_text } = user;
  const auth_Context = useContext(authContext);
  const { login, is_auth, is_loading } = auth_Context;

  const handlers_Context = useContext(handlersContext);
  const { set_loader, remove_loader, wait } = handlers_Context;

  const form = useRef();
  const email_ref = useRef();
  useEffect(() => {
    if (is_auth) {
      history.push('/fahrschule');
    }
    // eslint-disable-next-line
  }, [is_auth]);
  const onSubmit = async (e) => {
    e.preventDefault();

    // if (!Checks.email(email_ref.current.value)) {
    //   setUser({
    //     ...user,
    //     error_text: 'Bitte wähle eine gültige E-Mail-Adresse',
    //   });
    //   email_ref.current.classList.add('error');
    //   return;
    // } else {
    //   setUser({
    //     ...user,
    //     error_text: '',
    //   });
    //   email_ref.current.classList.remove('error');
    // }

    const inputs = form.current.querySelectorAll('input');
    let error_bool = false;
    inputs.forEach((input) => {
      if (!Checks.not_empty(input.value)) {
        input.classList.add('error');
        error_bool = true;
      }
    });
    if (error_bool) {
      setUser({
        ...user,
        error_text: 'Bitte fülle alle Felder aus.',
      });
      return;
    }
    setUser({
      ...user,
      error_text: '',
    });

    set_loader();
    await wait(2000);
    const login_bool = await login({ email, password });
    if (!login_bool) {
      setUser({
        ...user,
        error_text: `Der Nutzername und das Passwort stimmen nicht mit unseren Unterlagen überein. Bitte überprüfe deine Angaben und versuche es erneut.`,
      });
    } else {
      setUser({
        ...user,
        error_text: ``,
      });
    }
    remove_loader();
  };
  const onBlurEmail = (e) => {
    return;
    // if (!Checks.email(e.target.value)) {
    //   setUser({
    //     ...user,
    //     error_text: 'Bitte wähle eine gültige E-Mail-Adresse',
    //   });
    //   e.target.classList.add('error');
    // } else {
    //   setUser({
    //     ...user,
    //     error_text: '',
    //   });
    //   e.target.classList.remove('error');
    // }
  };
  const onChange = (e) => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
  };

  const onFocus = (e) => {
    e.target.classList.remove('error');
  };
  return (
    <Fragment>
      {!is_loading && (
        <div className="container">
          <section className="user-form-container">
            <h2 className="title text-center">Bitte anmelden:</h2>

            <form ref={form} onSubmit={onSubmit} className="user-form">
              {error_text !== '' && <p className="error-top">{error_text}</p>}
              <div className="input-container">
                <input
                  ref={email_ref}
                  type="text"
                  placeholder="E-Mail-Adresse"
                  name="email"
                  value={email}
                  onChange={onChange}
                  onFocus={onFocus}
                  onBlur={onBlurEmail}
                />
                <small>Fehlermeldung</small>
              </div>

              <div className="input-container">
                <input
                  type="password"
                  placeholder="Passwort"
                  name="password"
                  value={password}
                  onFocus={onFocus}
                  onChange={onChange}
                />
                <small>Fehlermeldung</small>
              </div>

              <button type="submit" className="btn-off">
                Anmelden
              </button>
            </form>
          </section>
        </div>
      )}
    </Fragment>
  );
};

export default Login;
