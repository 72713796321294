import React, { useContext, Fragment, useRef, useEffect } from 'react';
import { handlersContext } from '../../context/handlers/HandlersState';

const Message = () => {
  const handlers_Context = useContext(handlersContext);
  const { message, remove_message, is_message } = handlers_Context;

  let messageRef = useRef();
  useEffect(() => {
    let handler = (e) => {
      if (!messageRef.current.contains(e.target) && is_message === true) {
        remove_message();
      }
    };
    document.addEventListener('mousedown', handler);
    return () => {
      document.removeEventListener('mousedown', handler);
    };
  });
  return (
    <Fragment>
      <div className={!is_message ? 'message' : 'message active'}>
        <div ref={messageRef} className="content">
          <div className="close">
            <i className="far fa-times-circle" onClick={remove_message}></i>
          </div>
          <p className="pt-4">{message}</p>
          {/* <div className="btn_container">
              <button className="transparent">Zurück</button>{' '}
              <button id="confirmBtn">Bestätigen</button>
            </div> */}
        </div>
      </div>
    </Fragment>
  );
};

export default Message;
