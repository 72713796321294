import React, { Fragment, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { ehContext } from '../../context/eh/EhState';
import TnTable from '../tn/TnTable';
const Suche = () => {
  const eh_Context = useContext(ehContext);
  const [pagination, setPagination] = useState({
    page: 1,
    count: false,
    tn_pro_seite: false,
    type: 0,
    direction: 'DESC',
    name: '',
    date: '',
  });
  const { set_tn } = eh_Context;
  useEffect(() => {
    const get_tn = async () => {
      try {
        const table_info = await axios.get(`/teilnehmer_api/table_info`);

        const res_kurs = await axios.get(
          `/teilnehmer_api?page=${pagination.page}`
        );
        const result = res_kurs.data.result;
        if (result === null) {
          return;
        } else {
          set_tn(result);
          setPagination({
            ...pagination,
            count: table_info.data.count,
            tn_pro_seite: table_info.data.tn_pro_seite,
          });
        }
      } catch (error) {
        console.log(error.response.data);
        return;
      }
    };

    get_tn();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const get_tn = async () => {
      try {
        const res_kurs = await axios.get(
          `/teilnehmer_api?page=${pagination.page}&sort_type=${pagination.type}&sort_direction=${pagination.direction}&search_date=${pagination.date}&search_name=${pagination.name}`
        );
        const result = res_kurs.data.result;
        if (result === null) {
          return;
        } else {
          set_tn(result);
        }
      } catch (error) {
        console.log(error.response.data);
        return;
      }
    };

    get_tn();
    // eslint-disable-next-line
  }, [pagination]);

  const onChange = async (e) => {
    const { value } = e.target;
    const values = value.split(' ');
    values.forEach((str) => {
      const split = str.split('');
      var n = Math.floor(Number(split[0]));
      if (n !== Infinity && String(n) === split[0] && n >= 0) {
        if (is_birthday(str)) {
          setPagination({
            ...pagination,
            page: 1,
            date: str,
          });
        } else {
          setPagination({
            ...pagination,
            page: 1,
            date: '',
          });
        }
      } else {
        if (str !== '') {
          setPagination({
            ...pagination,
            page: 1,
            name: str,
          });
        }
      }
    });
    if (values[0] === '') {
      setPagination({
        ...pagination,
        page: 1,
        date: '',
        name: '',
      });
    }
  };

  const is_birthday = (day) => {
    if (day === false) {
      return false;
    } else if (day === '' || day.length !== 10) {
      return false;
    }
    const split = day.split('');
    if (split[2] === '.' && split[5] === '.') {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Fragment>
      <section className="suche partner-admin">
        <div className="container">
          <div className="title">Teilnehmer Finden</div>
          <div className="input-container pb-2" onChange={onChange}>
            <input
              type="text"
              placeholder="Nachname und/oder Geburtstag und/oder Kurs-Datum (TT.MM.JJJJ)"
              id="search_input"
              className="input-100"
            />
          </div>
          <TnTable pagination={pagination} setPagination={setPagination} />
        </div>
      </section>
    </Fragment>
  );
};

export default Suche;
