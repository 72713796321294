export const GET_KURSE = 'GET_KURSE';
export const GET_WARTELISTE = 'GET_WARTELISTE';
export const GET_PARTNER = 'GET_PARTNER';
export const CLEAR_EH = 'CLEAR_EH';
export const SET_ONE_KURS = 'SET_ONE_KURS';
export const SET_ONE_PARTNER = 'SET_ONE_PARTNER';
export const GET_TN = 'GET_TN';
export const GET_ONE_TN = 'GET_ONE_TN';
export const GET_ONE_KURS = 'GET_ONE_KURS';
export const GET_ONE_PARTNER = 'GET_ONE_PARTNER';
export const SET_TN = 'SET_TN';
export const SET_ONE_TN = 'SET_ONE_TN';
export const SET_LOADING = 'SET_LOADING';
export const DELETE_PARTNER = 'DELETE_PARTNER';
// auth teil
export const LOADING = 'LOADING';
export const USER_LOADED = 'USER_LOADED';
export const USER_LOGGED = 'USER_LOGGED';
export const USER_LOGOUT = 'USER_LOGOUT';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT = 'LOGOUT';

// in Handlers
export const SET_LOADER = 'SET_LOADER';
export const REMOVE_LOADER = 'REMOVE_LOADER';
export const SET_MESSAGE = 'SET_MESSAGE';
export const REMOVE_MESSAGE = 'REMOVE_MESSAGE';
export const SET_IS_CLICKED = 'SET_IS_CLICKED';
export const SET_IMAGE_SRC = 'SET_IMAGE_SRC';
export const SET_PDF_SRC = 'SET_PDF_SRC';
