import React, { useContext, Fragment } from 'react';
import { handlersContext } from '../../context/handlers/HandlersState';

const Loader = () => {
  const handlers_Context = useContext(handlersContext);
  const { loading } = handlers_Context;

  return (
    <Fragment>
      <div className={`loader-overlay ${loading ? 'active' : ''}`}>
        <div className="loader">
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
        </div>
      </div>
    </Fragment>
  );
};

export default Loader;
