import React, { useContext, useEffect, useState, Fragment } from 'react';
import axios from 'axios';
import { handlersContext } from '../../context/handlers/HandlersState';
import { useHistory } from 'react-router-dom';
import KursForm from './KursForm';
import Dropdown from '../stuff/Dropdown';
const WartelisteAdmin = ({ action, id }) => {
  const handlers_Context = useContext(handlersContext);
  const {
    set_loader,
    set_message,
    remove_loader,
    wait,
    is_clicked,
    set_is_clicked,
  } = handlers_Context;
  let history = useHistory();
  const [state, setState] = useState({
    stadt: false,
    street_name: false,
    street: false,
    plz: false,
    city: false,
    price: false,
    dozent_id: 0,
    n_participant: false,
  });

  const [error, setError] = useState(false);
  const [error_text, setError_Text] = useState('');
  const [stadt, setStadt] = useState(false);
  const [staedte, setStaedte] = useState([]);
  useEffect(() => {
    const load_all_staedte = async () => {
      try {
        const res = await axios.get(`/kurse_api/staedte`);
        setStaedte(res.data.result);
      } catch (error) {
        console.log(error.response.data);
        set_message(error.response.data.error_text);
      }
    };
    if (action === 'create') {
      load_all_staedte();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const load_kurs = async () => {
      try {
        const res_kurs = await axios.get(`/kurse_api?id=${id}`);
        const result = res_kurs.data.result;
        if (result === null) {
          return;
        } else {
          setState({
            ...state,
            date: result.date,
            stadt: result.stadt,
            city: result.info.city,
            n_participant: result.n_participant,
            plz: result.info.plz,
            price: result.price,
            street: result.info.street,
            street_name: result.info.street_name,
          });
        }
      } catch (error) {
        console.log(error.response.data);
        set_message(error.response.data.error_text);
        return;
      }
    };
    if (action === 'edit') {
      load_kurs();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (stadt !== false) {
      setState({
        ...state,
        stadt: stadt,
      });
    }
    // eslint-disable-next-line
  }, [stadt]);

  const submit = async () => {
    if (is_clicked === true) {
      console.log('Double click');
      return;
    }
    set_is_clicked(true);
    setError(true);
    var valid = true;
    for (const prop in state) {
      if (state[prop] === false || state[prop] === '') {
        valid = false;
      }
    }
    if (!valid) {
      setError_Text('Bitte fülle alle Felder aus!');
      return set_is_clicked(false);
    } else {
      setError_Text('');
      set_loader();
      try {
        console.log(state);
        var res = null;
        if (action === 'create') {
          res = await axios.post(`/kurse_api/warteliste`, state);
        } else if (action === 'edit') {
          res = await axios.put(`/kurse_api/warteliste?id=${id}`, state);
        }
        console.log(res.data);
        await wait();
        remove_loader();
        set_is_clicked(false);
        history.goBack();
      } catch (err) {
        remove_loader();
        set_message(err.response.data.error_text);
        return set_is_clicked(false);
      }
    }
  };

  useEffect(() => {
    const load_warteliste = async () => {
      if (state.stadt !== false) {
        try {
          if (action === 'create') {
            var res = await axios.get(
              `/kurse_api/warteliste_exist/${state.stadt}`
            );
            if (res.data.result.length === 0) {
              return;
            } else {
              set_message(
                'Zu dieser Stadt existiert bereits eine Warteliste. Du kannst diese nun bearbeiten'
              );
            }
            history.push(
              '/fahrschule/warteliste-bearbeiten/' + res.data.result.id
            );
          }
        } catch (err) {
          set_message(err.response.data.error_text);
        }
      }
    };
    load_warteliste();
    // eslint-disable-next-line
  }, [state.stadt]);
  return (
    <Fragment>
      <div className="kurs_admin py-2">
        <div className="container admin_container">
          <div className="text-center pb-2">
            <button
              onClick={() => {
                history.goBack();
              }}
            >
              Zurück
            </button>
          </div>
          <h1 className="text-center py-2">
            {action === 'create'
              ? 'Neue Warteliste anlegen'
              : `Warteliste in ${state.stadt} bearbeiten`}
          </h1>
          {action === 'create' ? (
            <div className="stadt-container pb-4">
              <p style={{ fontSize: '1.6rem' }} className="pb-2">
                Stadt:
              </p>
              <Dropdown
                elements={staedte}
                choosen={stadt}
                setChoosen={setStadt}
                error={error}
              />
            </div>
          ) : (
            <Fragment>
              <div className="text-center pt-2 ">
                <button
                  onClick={() => {
                    history.push('/fahrschule/kurs/' + id);
                  }}
                >
                  Teilnehmer
                </button>
              </div>
            </Fragment>
          )}

          <KursForm
            state={state}
            setState={setState}
            error={error}
            dozents={[]}
            setDozents={null}
            is_warteliste={true}
          />
          <div className="text-center py-2">
            <div
              className={
                error_text !== '' ? 'error-text pb-2' : 'error-text hide pb-2'
              }
            >
              {error_text}
            </div>

            <button onClick={submit} className="btn-green">
              {action === 'create' ? 'Erstellen' : 'Speichern'}
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default WartelisteAdmin;
