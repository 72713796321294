import React, { useContext, useEffect, useState, Fragment } from 'react';
import axios from 'axios';
import { handlersContext } from '../../context/handlers/HandlersState';
import Dropdown from '../stuff/Dropdown';
import ConfirmStep from '../handlers/ConfirmStep';
const Admin = () => {
  const handlers_Context = useContext(handlersContext);
  const { set_message, set_loader, remove_loader } = handlers_Context;
  const [isLoading, setIsLoading] = useState(true);
  const [oneWebiste, setOneWebiste] = useState('');
  const oneWebisteArray = ['reguläre Kurse', 'Warteliste'];
  const [accordion1, setAccordion1] = useState('');
  const [accordion2, setAccordion2] = useState('');
  const [accordion3, setAccordion3] = useState('');
  const [bezahlung1, setBezahlung1] = useState('');
  const [bezahlung2, setBezahlung2] = useState('');
  const [bezahlung3, setBezahlung3] = useState('');
  const [bezahlung4, setBezahlung4] = useState('');
  const bezahlungen = ['Paypal', 'Klarna', 'Kreditkarte', 'SEPA', 'leer'];
  const accordion = ['Novalnet', 'Vorkasse', 'leer'];

  const [confirm_neue_kurse_email, setConfirm_neue_kurse_email] = useState({
    question:
      'Möchtest du wirklich E-Mails an alle Teilnehmer auf der Warteliste verschicken, dass es wieder neue Kurse gibt?',
    confirm_text: 'Bestätigen',
    reject_text: 'Verwerfen',
    answer: false,
    active: false,
    type: 'neue_kurse',
  });

  const [confirm_auf_warteliste, setConfirm_auf_warteliste] = useState({
    question: 'Möchtest wirklich alle Teilnehmer auf die Warteliste setzen?',
    confirm_text: 'Bestätigen',
    reject_text: 'Verwerfen',
    answer: false,
    active: false,
    type: 'auf_warteliste',
  });

  const transfrom = (str, invers = false) => {
    if (invers === false) {
      switch (str) {
        case 'novalnet':
          return 'Novalnet';
        case 'sepa':
          return 'SEPA';
        case 'vorkasse':
          return 'Vorkasse';
        case 'creditcard':
          return 'Kreditkarte';
        case 'klarna':
          return 'Klarna';
        case 'paypal':
          return 'Paypal';
        default:
          return 'leer';
      }
    } else {
      switch (str) {
        case 'Novalnet':
          return 'novalnet';
        case 'SEPA':
          return 'sepa';
        case 'Vorkasse':
          return 'vorkasse';
        case 'Kreditkarte':
          return 'creditcard';
        case 'Klarna':
          return 'klarna';
        case 'Paypal':
          return 'paypal';
        default:
          return 'leer';
      }
    }
  };
  useEffect(() => {
    const load_config_change = async (transform) => {
      try {
        const res_kurs = await axios.get(`/admin_api/get_config_change`);
        const result = res_kurs.data.result;
        setOneWebiste(
          result.on_website === 'regular' ? 'reguläre Kurse' : 'Warteliste'
        );

        setAccordion1(transform(result.payment_1));
        setAccordion2(transform(result.payment_2));
        setAccordion3(transform(result.payment_3));

        setBezahlung1(transform(result.novalnet_payment_1));
        setBezahlung2(transform(result.novalnet_payment_2));
        setBezahlung3(transform(result.novalnet_payment_3));
        setBezahlung4(transform(result.novalnet_payment_4));

        setIsLoading(false);
      } catch (error) {
        console.log(error.response.data);
        set_message(error.response.data.error_text);
      }
    };
    load_config_change(transfrom);
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    const change_on_website = async () => {
      if (isLoading === true || oneWebiste === '') {
        return;
      }
      try {
        const type = oneWebiste === 'Warteliste' ? 'warteliste' : 'regular';
        await axios.put(`/admin_api/set_show_on_website?type=${type}`);
      } catch (error) {
        console.log(error.response.data);
        set_message(error.response.data.error_text);
      }
    };
    change_on_website(transfrom, bezahlungen);
    // eslint-disable-next-line
  }, [oneWebiste]);

  const change_bezahlung_accordion = async (order, type) => {
    try {
      await axios.put(
        `/admin_api/set_payment_accordion?order=${order}&type=${type}`
      );
    } catch (error) {
      console.log(error.response.data);
      set_message(error.response.data.error_text);
    }
  };
  useEffect(
    () => {
      if (isLoading === true || accordion1 === '') {
        return;
      } else {
        const type = transfrom(accordion1, true);
        const order = 1;
        change_bezahlung_accordion(order, type);
      }
    },
    // eslint-disable-next-line
    [accordion1]
  );
  useEffect(
    () => {
      if (isLoading === true || accordion2 === '') {
        return;
      } else {
        const type = transfrom(accordion2, true);
        const order = 2;
        change_bezahlung_accordion(order, type);
      }
    },
    // eslint-disable-next-line
    [accordion2]
  );
  useEffect(
    () => {
      if (isLoading === true || accordion3 === '') {
        return;
      } else {
        const type = transfrom(accordion3, true);
        const order = 3;
        change_bezahlung_accordion(order, type);
      }
    },
    // eslint-disable-next-line
    [accordion3]
  );

  const change_bezahlung_novalnet = async (order, type) => {
    try {
      await axios.put(
        `/admin_api/set_payment_novalnet?order=${order}&type=${type}`
      );
    } catch (error) {
      console.log(error.response.data);
      set_message(error.response.data.error_text);
    }
  };
  useEffect(
    () => {
      if (isLoading === true || bezahlung1 === '') {
        return;
      } else {
        const type = transfrom(bezahlung1, true);
        const order = 1;
        change_bezahlung_novalnet(order, type);
      }
    },
    // eslint-disable-next-line
    [bezahlung1]
  );
  useEffect(
    () => {
      if (isLoading === true || bezahlung2 === '') {
        return;
      } else {
        const type = transfrom(bezahlung2, true);
        const order = 2;
        change_bezahlung_novalnet(order, type);
      }
    },
    // eslint-disable-next-line
    [bezahlung2]
  );
  useEffect(
    () => {
      if (isLoading === true || bezahlung3 === '') {
        return;
      } else {
        const type = transfrom(bezahlung3, true);
        const order = 3;
        change_bezahlung_novalnet(order, type);
      }
    },
    // eslint-disable-next-line
    [bezahlung3]
  );
  useEffect(
    () => {
      if (isLoading === true || bezahlung4 === '') {
        return;
      } else {
        const type = transfrom(bezahlung4, true);
        const order = 4;
        change_bezahlung_novalnet(order, type);
      }
    },
    // eslint-disable-next-line
    [bezahlung4]
  );

  const neue_kurse_email = async () => {
    try {
      set_loader();
      await axios.get(`/admin_api/neue_kurse_email`);
      remove_loader();
      return;
    } catch (error) {
      console.log(error.response.data);
      set_message(error.response.data.error_text);
      remove_loader();
    }
  };
  useEffect(() => {
    if (confirm_neue_kurse_email.answer === true) {
      neue_kurse_email();
    }
    // eslint-disable-next-line
  }, [confirm_neue_kurse_email.answer]);

  const auf_warteliste = async () => {
    try {
      set_loader();
      await axios.get(`/admin_api/alle_tn_auf_warteliste`);
      remove_loader();
      return;
    } catch (error) {
      console.log(error.response.data);
      set_message(error.response.data.error_text);
      remove_loader();
    }
  };
  useEffect(() => {
    if (confirm_auf_warteliste.answer === true) {
      auf_warteliste();
    }
    // eslint-disable-next-line
  }, [confirm_auf_warteliste.answer]);
  return (
    <Fragment>
      <ConfirmStep
        confirm={confirm_neue_kurse_email}
        setConfirm={setConfirm_neue_kurse_email}
      />
      <ConfirmStep
        confirm={confirm_auf_warteliste}
        setConfirm={setConfirm_auf_warteliste}
      />
      <section className="admin">
        <div className="container pb-5 pt-2">
          <h1 className="text-center pt-1">Einstellungen verwalten</h1>

          <div className="one-admin pt-3">
            <div className="title">
              Welche Kurse sollen auf der Startseite zu sehen sein?
            </div>
            <div className="drop-container pt-2">
              <Dropdown
                elements={oneWebisteArray}
                choosen={oneWebiste}
                setChoosen={setOneWebiste}
                error={false}
              />
            </div>
          </div>
          <div className="one-admin pt-3">
            <div className="title">Warteliste:</div>
            <div className="drop-container pt-2">
              <button
                onClick={() => {
                  setConfirm_auf_warteliste({
                    ...confirm_auf_warteliste,
                    active: true,
                  });
                }}
              >
                Alle Teilnehmer auf Warteliste setzen
              </button>
            </div>
          </div>
          <div className="one-admin pt-3">
            <div className="title">
              E-Mail an Teilnehmer, dass es wieder Kurse gibt
            </div>
            <div className="drop-container pt-2">
              <button
                onClick={() => {
                  setConfirm_neue_kurse_email({
                    ...confirm_neue_kurse_email,
                    active: true,
                  });
                }}
              >
                E-Mails versenden{' '}
              </button>
            </div>
          </div>
          <div className="one-admin pt-3">
            <div className="title">Bezahlungsmöglichkeiten in Akkordeon:</div>
            <p>
              -Akkordeon Ansicht nur falls mehr wie eine Stelle besetzt ist!{' '}
              <br />
              -Erste Stelle ist aufgeklappt!
            </p>
            <div className="drop-container pt-2">
              <p>Stelle 1:</p>
              <Dropdown
                elements={accordion}
                choosen={accordion1}
                setChoosen={setAccordion1}
                error={false}
              />
            </div>
            <div className="drop-container pt-2">
              <p>Stelle 2:</p>
              <Dropdown
                elements={accordion}
                choosen={accordion2}
                setChoosen={setAccordion2}
                error={false}
              />
            </div>
            <div className="drop-container pt-2">
              <p>Stelle 3:</p>
              <Dropdown
                elements={accordion}
                choosen={accordion3}
                setChoosen={setAccordion3}
                error={false}
              />
            </div>
          </div>
          <div className="one-admin pt-3">
            <div className="title">Bezahlungsmöglichkeiten In Novalnet:</div>
            <div className="drop-container pt-2">
              <p>Stelle 1:</p>
              <Dropdown
                elements={bezahlungen}
                choosen={bezahlung1}
                setChoosen={setBezahlung1}
                error={false}
              />
            </div>
            <div className="drop-container pt-2">
              <p>Stelle 2:</p>
              <Dropdown
                elements={bezahlungen}
                choosen={bezahlung2}
                setChoosen={setBezahlung2}
                error={false}
              />
            </div>
            <div className="drop-container pt-2">
              <p>Stelle 3:</p>
              <Dropdown
                elements={bezahlungen}
                choosen={bezahlung3}
                setChoosen={setBezahlung3}
                error={false}
              />
            </div>
            <div className="drop-container pt-2">
              <p>Stelle 4:</p>
              <Dropdown
                elements={bezahlungen}
                choosen={bezahlung4}
                setChoosen={setBezahlung4}
                error={false}
              />
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Admin;
