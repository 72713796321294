import React, { Fragment, useContext } from 'react';
import { ehContext } from '../../context/eh/EhState';
import Times from '../../utils/Times';
const KursShowcase = () => {
  const eh_Context = useContext(ehContext);
  const { one_kurs, rest_plaetze } = eh_Context;
  if (one_kurs === null) {
    return <Fragment>Loading</Fragment>;
  }
  var open_html = '';
  if (one_kurs.open > rest_plaetze) {
    open_html = <p className="platz">freie Plätze</p>;
  } else if (one_kurs.open <= rest_plaetze && one_kurs.open > 0) {
    open_html = <p className="platz wenig">wenige Plätze</p>;
  } else {
    open_html = <p className="platz booked">ausgebucht</p>;
  }
  return (
    <Fragment>
      {one_kurs !== null && (
        <div className="kurs_showcase">
          <div className="row">
            <div className="col-md-3 image_container">
              <img
                src="/img/kurse/kurse_unter.jpg"
                alt="Ein Kurs zur Ersten Hilfe"
              />
            </div>
            <div className="col-md-9 z-info">
              <div className="main_content">
                <div className="termin_container">
                  {one_kurs.date === 'Warteliste' ? (
                    <h3 className="termin">Warteliste {one_kurs.stadt}</h3>
                  ) : (
                    <Fragment>
                      <h3 className="termin">
                        {Times.get_weekday(one_kurs.date)} {one_kurs.date}
                      </h3>
                      <p className="hour">
                        Von {one_kurs.info.start} Uhr bis {one_kurs.info.end}{' '}
                        Uhr
                      </p>
                    </Fragment>
                  )}
                </div>
              </div>
              <div className="second-content">
                <div className="left-part">
                  {one_kurs.dozent_name !== '' ? (
                    <p className="kurs_leitung">Mit {one_kurs.dozent_name}</p>
                  ) : (
                    ''
                  )}
                  <p className="adress-name">{one_kurs.info.street_name}</p>
                  <p className="street">{one_kurs.info.street}</p>
                  <p className="plz">
                    {one_kurs.info.plz} {one_kurs.info.city}
                  </p>
                </div>
                <div className="right-part">
                  <div className="platz_container">{open_html}</div>
                  <div className="check_container">
                    <div className="preis_container">
                      <p className="preis">
                        nur <span className="price">{one_kurs.price}</span>€
                      </p>
                    </div>
                    <div className="btn_container"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default KursShowcase;
