import React, { Fragment, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import Checks from '../../utils/Checks';
import { useHistory } from 'react-router-dom';
import { handlersContext } from '../../context/handlers/HandlersState';
import PartnerForm from './PartnerForm';
import ConfirmStep from '../handlers/ConfirmStep';

const PartnerAdmin = ({ action, id }) => {
  let history = useHistory();
  const handlers_Context = useContext(handlersContext);
  const {
    set_loader,
    set_message,
    remove_loader,
    wait,
    set_is_clicked,
    is_clicked,
  } = handlers_Context;
  const [state, setState] = useState({
    name: false,
    email: false,
    password: false,
    status: 'partner',
  });
  const [error_text, setError_Text] = useState('');
  const [error, setError] = useState(false);
  const [confirm, setConfirm] = useState({
    question: '',
    confirm_text: 'Bestätigen',
    reject_text: 'Verwerfen',
    answer: false,
    active: false,
    type: 'type',
    extra_info: 'id',
  });

  useEffect(() => {
    const load_user = async () => {
      try {
        const res_kurs = await axios.get(`/users_api?id=${id}`);
        const result = res_kurs.data.result;
        if (result === null) {
          return;
        } else {
          setState({
            ...state,
            name: result.name,
            email: result.email,
            password: result.password,
            status: result.status,
          });
          setConfirm({
            ...confirm,
            question: `Möchtest du den Parnter am <span class="red-text">${result.name} wirklich löschen ?`,
          });
        }
      } catch (error) {
        console.log(error.response.data);
        return;
      }
    };

    if (action === 'edit') {
      load_user();
    }

    // eslint-disable-next-line
  }, []);

  const submit = async () => {
    if (is_clicked === true) {
      console.log('Double click');
      return;
    }
    set_is_clicked(true);
    setError(true);
    var valid = true;
    for (const prop in state) {
      if (state[prop] === false || state[prop] === '') {
        valid = false;
      }
    }

    if (!Checks.email(state.email)) {
      valid = false;
    }
    console.log(state);
    if (valid === false) {
      console.log(state);
      setError_Text('Bitte fülle alle Felder aus!');
      return set_is_clicked(false);
    } else {
      setError_Text('');
      set_loader();
      try {
        var res = null;
        if (action === 'create') {
          res = await axios.post(`/users_api`, state);
        } else if (action === 'edit') {
          res = await axios.put(`/users_api?&id=${id}`, state);
        } else {
          console.log(error);
          return set_is_clicked(false);
        }
        console.log(res.data);
        await wait();
        remove_loader();
        set_is_clicked(false);
        history.goBack();
      } catch (err) {
        remove_loader();
        console.log(err.response.data);
        if (err.response.data.type === 'inactive_account') {
          setConfirm({
            ...confirm,
            question:
              'Zu dieser E-Mail-Adresse existiert bereits ein Account. Möchtest du diesen Account wieder aktivieren mit all seinen alten Daten?',
            active: true,
            type: 'inactive_account',
            extra_info: err.response.data.id,
          });
        } else {
          set_message(err.response.data.error_text);
        }

        return set_is_clicked(false);
      }
    }
  };

  const set_status_partner = async (id, status) => {
    try {
      set_loader();
      const data = {
        status: status,
      };

      await axios.put(`/users_api/new_status?id=${id}`, data);
      await wait();
      remove_loader();
      history.goBack();
    } catch (err) {
      set_message(err.response.data.error_text);
      remove_loader();
    }
  };

  useEffect(() => {
    if (confirm.answer === true) {
      if (confirm.type === 'inactive_account') {
        set_status_partner(confirm.extra_info, 'partner');
      } else if (confirm.type === 'delete') {
        set_status_partner(confirm.extra_info, 'inactive');
      }
    }
    // eslint-disable-next-line
  }, [confirm.answer]);

  return (
    <Fragment>
      <ConfirmStep confirm={confirm} setConfirm={setConfirm} />
      <section className="partner-bg-gray">
        <div className="container partner-admin pt-2">
          <div className="text-center py-2">
            <button
              onClick={() => {
                history.goBack();
              }}
              className="btn"
            >
              Zurück
            </button>
          </div>
          <h1 className="title text-center py-2">
            {action === 'create'
              ? 'Erstelle einen neunen Partner'
              : 'Partner bearbeiten'}
          </h1>
          {action === 'edit' && (
            <div className="btn-container-3 pb-4">
              <button
                onClick={() => {
                  history.push(`/fahrschule/teilnehmer-partner/${id}`);
                }}
              >
                Teilnehmer
              </button>
              {(state.status === 'partner' || state.status === 'dozent') && (
                <button
                  className="btn-red"
                  onClick={() => {
                    setConfirm({
                      ...confirm,
                      question: `Möchtest du den Partner <span class="red-text">${state.name}</span> wirklich löschen?`,
                      active: true,
                      type: 'delete',
                      extra_info: id,
                    });
                  }}
                >
                  Löschen
                </button>
              )}
            </div>
          )}
          <div className="success-nachricht">
            Zugangsdaten für den Partner:
            <div className="js_partner py-1">E-Mail-Adresse und Passwort</div>
          </div>

          <PartnerForm state={state} setState={setState} error={error} />

          <div className="text-center pb-5">
            <div
              className={
                error_text !== '' ? 'error-text pb-2' : 'error-text hide pb-2'
              }
            >
              {error_text}
            </div>

            <button onClick={submit}>
              {action === 'create' ? 'Erstellen ' : 'Speichern'}
            </button>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default PartnerAdmin;
