import React, { Fragment, useEffect, useState } from 'react';
import axios from 'axios';
import Times from '../../utils/Times';
import { stringify } from 'uuid';

const WebsiteShowcase = ({ id, show_open, is_tn }) => {
  const [one_kurs, setOne_Kurs] = useState(null);

  const [open_html, setOpen_Html] = useState('');

  const [price, setPrice] = useState('');
  const [gutschein, setGutschein] = useState(null);
  const to_price = (x) => {
    var string = x.toString();
    string = string.replace('.', ',');
    var index = string.indexOf(',');
    if (index === -1) {
      return string + ',00';
    } else {
      var sub = string.substring(index + 1, index + 3);
      if (sub.length === 2) {
        return string;
      } else {
        return string + '0';
      }
    }
  };

  useEffect(() => {
    const load_kurs = async () => {
      try {
        if (is_tn !== false) {
          const res_tn = await axios.get(
            `/website_api/get_one_tn/?id=${is_tn}`
          );

          const bezahlung = res_tn.data.result.bezahlung;
          const last = bezahlung[bezahlung.length - 1];
          if (last.gutschein_id === null) {
            var betrag = last.betrag.toString();
            setPrice(to_price(betrag));
          } else {
            setGutschein({
              new_betrag: to_price(last.betrag),
              rabatt:
                last.type === 'in_percent'
                  ? `- ${to_price(last.rabatt)} % `
                  : `- ${to_price(last.rabatt)} € `,
            });
          }
        }
        const res_kurs = await axios.get(`/website_api/get_one_kurs/?id=${id}`);
        const result = res_kurs.data.result;
        if (result === null) {
          window.location.href = '/';
          return;
        } else {
          setOne_Kurs(result);

          if (show_open === true) {
            console.log(res_kurs.data.rest_plaetze);
            if (result.open > res_kurs.data.rest_plaetze) {
              setOpen_Html(<p className="platz">freie Plätze</p>);
            } else if (
              result.open <= res_kurs.data.rest_plaetze &&
              result.open > 0
            ) {
              setOpen_Html(<p className="platz wenig">wenige Plätze</p>);
            } else {
              setOpen_Html(<p className="platz booked">ausgebucht</p>);
            }
          }
        }
      } catch (error) {
        console.log(error.response.data);
        window.location.href = '/';
        return;
      }
    };
    load_kurs();
    // eslint-disable-next-line
  }, []);

  if (one_kurs === null) {
    return <Fragment>Loading</Fragment>;
  }

  return (
    <Fragment>
      {one_kurs !== null && (
        <div className="website-kurs_showcase">
          <div className="row">
            <div className="col-md-3 image_container">
              <img
                src="/img/kurse/kurse_unter.jpg"
                alt="Ein Kurs zur Ersten Hilfe"
              />
            </div>
            <div className="col-md-9 z-info">
              <div className="main_content">
                <div className="termin_container">
                  {one_kurs.date === 'Warteliste' ? (
                    <h3 className="termin">Warteliste {one_kurs.stadt}</h3>
                  ) : (
                    <Fragment>
                      <h3 className="termin">
                        {Times.get_weekday(one_kurs.date)} {one_kurs.date}
                      </h3>
                      <p className="hour">
                        Von {one_kurs.info.start} Uhr bis {one_kurs.info.end}{' '}
                        Uhr
                      </p>
                    </Fragment>
                  )}
                </div>
              </div>
              <div className="second-content">
                <div className="left-part">
                  {one_kurs.dozent_name !== '' ? (
                    <p className="kurs_leitung">Mit {one_kurs.dozent_name}</p>
                  ) : (
                    ''
                  )}
                  <p className="adress-name">{one_kurs.info.street_name}</p>
                  <p className="street">{one_kurs.info.street}</p>
                  <p className="plz">
                    {one_kurs.info.plz} {one_kurs.info.city}
                  </p>
                </div>
                <div className="right-part">
                  <div className="platz_container">{open_html}</div>
                  <div className="check_container">
                    <div className="preis_container">
                      {is_tn === false ? (
                        <p className="preis">
                          nur <span className="price">{one_kurs.price}</span>€
                        </p>
                      ) : (
                        <Fragment>
                          {gutschein !== null ? (
                            <Fragment>
                              <p className="preis">
                                nur{' '}
                                <span className="price-cross-out">
                                  {one_kurs.price} €
                                </span>
                              </p>
                              <p className="preis-rabatt">
                                {' '}
                                {gutschein.rabatt}
                              </p>
                              <p className="preis-neu">
                                {gutschein.new_betrag} €
                              </p>
                            </Fragment>
                          ) : (
                            <p className="preis">
                              nur <span className="price">{price}</span>€
                            </p>
                          )}
                        </Fragment>
                      )}
                    </div>
                    <div className="btn_container"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default WebsiteShowcase;
