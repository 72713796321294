import React, { Fragment, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import TnTable from '../tn/TnTable';
import { handlersContext } from '../../context/handlers/HandlersState';
import { useHistory } from 'react-router-dom';
import { ehContext } from '../../context/eh/EhState';

const GutscheineTn = ({ id }) => {
  const history = useHistory();
  const [pagination, setPagination] = useState({
    page: 1,
    count: false,
    tn_pro_seite: false,
    type: 0,
    direction: 'DESC',
    name: '',
    date: '',
  });
  const eh_Context = useContext(ehContext);
  const { set_tn, clear_eh } = eh_Context;
  const handlers_Context = useContext(handlersContext);
  const { set_message } = handlers_Context;
  const [state, setState] = useState({
    id: 0,
    status: 'active',
    code: false,
    rabatt: false,
    anzahl: false,
    expiration_date: false,
    gebucht: 0,
    bezahlt: 0,
  });
  useEffect(() => {
    const get_tn = async () => {
      try {
        const table_info = await axios.get(
          `/teilnehmer_api/table_info?gutschein_id=${id}`
        );
        const res_kurs = await axios.get(`/teilnehmer_api?gutschein_id=${id}`);
        const result = res_kurs.data.result;
        if (result === null) {
          return;
        } else {
          set_tn(result);
          setPagination({
            ...pagination,
            count: table_info.data.count,
            tn_pro_seite: table_info.data.tn_pro_seite,
          });
        }
      } catch (error) {
        console.log(error.response.data);
        return;
      }
    };
    const get_gutschein = async () => {
      try {
        const res_kurs = await axios.get(`/gutscheine_api?id=${id}`);
        const result = res_kurs.data.result;
        if (result === null) {
          return;
        } else {
          setState(result);
        }
      } catch (error) {
        console.log(error.response.data);
        set_message(error.response.data.error_text);
        return;
      }
    };

    get_gutschein();
    get_tn();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const get_tn = async () => {
      try {
        const res_kurs = await axios.get(
          `/teilnehmer_api?gutschein_id=${id}&page=${pagination.page}&sort_type=${pagination.type}&sort_direction=${pagination.direction}`
        );
        const result = res_kurs.data.result;
        if (result === null) {
          return;
        } else {
          set_tn(result);
        }
      } catch (error) {
        console.log(error.response.data);
        return;
      }
    };

    get_tn();
    // eslint-disable-next-line
  }, [pagination]);
  return (
    <div>
      <Fragment>
        <div className="text-center py-2">
          <button
            onClick={() => {
              clear_eh();
              history.goBack();
            }}
          >
            Zurück
          </button>
        </div>
        <div className="text-center gutschein">
          <h2>Gutschein : {state.code}</h2>

          <div className="statistik-container btn-container-3">
            <p>
              Ende: <span>{state.expiration_date}</span>
            </p>
            <p>
              bezahlt: <span>{state.bezahlt}</span>
            </p>
            <p>
              gebucht: <span>{state.gebucht}</span>
            </p>
          </div>
        </div>
      </Fragment>
      <TnTable pagination={pagination} setPagination={setPagination} />
    </div>
  );
};

export default GutscheineTn;
