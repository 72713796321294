import React, { Fragment } from 'react';
import Checks from '../../utils/Checks';
import Dropdown from '../stuff/Dropdown';
const PartnerForm = ({ state, setState, error }) => {
  const onChange = (e) => {
    var { name, value } = e.target;
    if (value === '') {
      value = false;
    }
    setState({
      ...state,
      [name]: value,
    });
  };
  const onFocus = (e) => {
    e.target.classList.remove('error');
    e.target.parentNode.classList.remove('error');
  };

  const transform_parnter = (value, invers = false) => {
    if (invers === false) {
      switch (value) {
        case 'partner':
          return 'Partner';
        case 'admin':
          return 'Admin';
        case 'dozent':
          return 'Dozent';
        default:
          return '----';
      }
    } else {
      switch (value) {
        case 'Partner':
          return 'partner';
        case 'Admin':
          return 'admin';
        case 'Dozent':
          return 'dozent';
        default:
          return '----';
      }
    }
  };

  const onBlur = (e) => {
    var { name, value } = e.target;
    var is_valid = true;
    if (name === 'email') {
      console.log(324);
      is_valid = Checks.email(value);
    }
    console.log(is_valid);
    if (is_valid === false) {
      console.log(234);
      e.target.parentNode.classList.add('error');
      e.target.classList.add('error');
      return;
    } else {
      e.target.parentNode.classList.remove('error');
      e.target.classList.remove('error');
      return;
    }
  };

  const set_partner = (el) => {
    setState({
      ...state,
      status: transform_parnter(el, true),
    });
  };

  return (
    <Fragment>
      <div className="partner-form pb-3">
        <div className="row">
          <div className="col-12 pt-2">
            <div className="input-container">
              <p>Status:</p>
              <Dropdown
                elements={['Admin', 'Partner', 'Dozent']}
                choosen={transform_parnter(state.status)}
                setChoosen={set_partner}
                error={error}
              />
            </div>
          </div>
          <div className="col-12 pt-2">
            <div className="input-container">
              <p>Name:</p>
              <input
                className={
                  error === true && state.name === false ? 'error' : ''
                }
                onFocus={onFocus}
                onChange={onChange}
                name="name"
                type="text"
                value={!state.name ? '' : state.name}
              />{' '}
            </div>
          </div>
          <div className="col-12 pt-2 ">
            <div className="input-container">
              <p>E-Mail-Adresse:</p>
              <input
                className={
                  error === true && state.email === false ? 'error' : ''
                }
                onFocus={onFocus}
                onChange={onChange}
                onBlur={onBlur}
                name="email"
                type="text"
                value={!state.email ? '' : state.email}
              />
              <small>Bitte wähle eine gültige E-Mail-Adresse</small>{' '}
            </div>
          </div>
          <div className="col-12 pt-2">
            <div className="input-container">
              <p>Password:</p>
              <input
                className={
                  error === true && state.password === false ? 'error' : ''
                }
                onFocus={onFocus}
                onChange={onChange}
                name="password"
                type="text"
                value={!state.password ? '' : state.password}
              />{' '}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PartnerForm;
