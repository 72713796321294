import React, { useContext, useEffect, Fragment, useState } from 'react';
import axios from 'axios';
import { handlersContext } from '../../context/handlers/HandlersState';
import WebsiteUmbuchenShowcase from './WebsiteUmbuchenShowcase';
import WebsiteFooter from './WebsiteFooter';
const WebsiteUmbuchenTooLate = ({ id }) => {
  const handlers_Context = useContext(handlersContext);
  const { set_message, remove_loader, set_loader, wait } = handlers_Context;
  const [oneTn, setOneTn] = useState(null);
  const [kurse, setKurse] = useState(null);
  const [text, setText] = useState(null);
  const [rest_plaetze, setRest_plaetze] = useState(0);
  useEffect(() => {
    // check if already payed...
    const load_db = async () => {
      try {
        const res = await axios.get(`/website_api/get_kurse`);
        const result = res.data.result;
        const res_tn = await axios.get(`/website_api/get_one_tn?id=${id}`);
        const result_tn = res_tn.data.result;
        if (result_tn.date !== 'Warteliste') {
          window.location.href = '/eh/erfolg/' + id;
        }
        setOneTn(result_tn);
        setKurse(result);
        console.log(result);
        setRest_plaetze(res.data.rest_plaetze);
        const res_text = await axios.get(
          `/website_api/get_text?type=buchen/umbuchen_zu_spaet_bezahlt`
        );
        setText(res_text.data.text);
        console.log(text);
      } catch (error) {
        console.log(error.response.data);
        set_message(error.response.data.error_text);
      }
    };
    load_db();
    // eslint-disable-next-line
  }, []);

  const umbuchen_sumbmit = async (kurs_id) => {
    try {
      set_loader();
      const data = {
        tn_id: id,
        kurs_id: kurs_id,
      };

      await axios.put('/website_api/umbuchen', data);

      await wait(1000);
      remove_loader();
      window.location.href = '/eh/erfolg/' + id;
    } catch (err) {
      set_message(err.response.data.error_text);
      remove_loader();
    }
  };

  return (
    <Fragment>
      <section className="website-umbuchen">
        <div className="container">
          {oneTn !== null && kurse !== null && (
            <Fragment>
              <div className="content-container">
                <div className="title">
                  <span>
                    Hallo {oneTn.info.vorname} {oneTn.info.nachname}
                  </span>
                </div>

                <div className="server-text">
                  <div dangerouslySetInnerHTML={{ __html: text }}></div>
                </div>
              </div>

              <div className="kurse-container">
                <div className="text-center sub-title py-4">Alle Kurse</div>
                {kurse.map((one_kurs, index) => {
                  return (
                    <WebsiteUmbuchenShowcase
                      key={index}
                      one_kurs={one_kurs}
                      rest_plaetze={rest_plaetze}
                      submit={umbuchen_sumbmit}
                    />
                  );
                })}
              </div>
            </Fragment>
          )}
        </div>
      </section>
      <WebsiteFooter />
    </Fragment>
  );
};

export default WebsiteUmbuchenTooLate;
