import React, { useContext, useEffect, Fragment } from 'react';
import PartnerRow from './PartnerRow';
import { ehContext } from '../../context/eh/EhState';

const PartnerTable = () => {
  const eh_Context = useContext(ehContext);
  const { get_partner, partner } = eh_Context;

  useEffect(() => {
    get_partner();
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <div className="container">
        <div className="responsive-table">
          <table>
            <thead>
              <tr>
                <th>Nr</th>
                <th>Name</th>
                <th>Status</th>
                <th className="rp-col-2">Email</th>
                <th className="rp-col-1">Password</th>
                <th className="rp-col-1">Teilnehmer</th>
              </tr>
            </thead>
            <tbody>
              {partner.map((partner, index) => {
                return (
                  <PartnerRow key={index} partner={partner} index={index} />
                );
              })}
            </tbody>
          </table>
          <p className="text-center">bei Teilnehmer: (bezahlt/gebucht)</p>
        </div>
      </div>
    </Fragment>
  );
};
export default PartnerTable;
