import { LOGIN_SUCCESS, USER_LOGGED, USER_LOGOUT } from '../types';

const authReducer = (state, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
    case USER_LOGGED:
      return {
        ...state,
        is_auth: true,
        is_loading: false,
        user_status: action.payload.user_status,
      };
    case USER_LOGOUT:
      return {
        ...state,
        is_auth: false,
        is_loading: false,
        user_status: null,
      };
    default:
      return state;
  }
};

export default authReducer;
