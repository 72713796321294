import {
  GET_KURSE,
  REMOVE_MESSAGE,
  SET_LOADER,
  CLEAR_EH,
  SET_ONE_KURS,
  GET_TN,
  GET_ONE_TN,
  SET_ONE_TN,
  SET_TN,
  GET_WARTELISTE,
  GET_PARTNER,
  SET_ONE_PARTNER,
  SET_LOADING,
  GET_ONE_KURS,
  GET_ONE_PARTNER,
  DELETE_PARTNER,
} from '../types';

const ehReducer = (state, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case GET_WARTELISTE:
      return {
        ...state,
        warteliste: action.payload,
      };
    case GET_KURSE:
      return {
        ...state,
        kurse: action.payload,
      };
    case GET_ONE_KURS:
      return {
        ...state,
        one_kurs: action.payload.kurs,
        rest_plaetze: action.payload.rest_plaetze,
      };
    case GET_ONE_PARTNER:
      return {
        ...state,
        one_partner: action.payload.partner,
      };
    case SET_ONE_KURS:
      return {
        ...state,
        one_kurs: action.payload,
      };
    case GET_PARTNER:
      return {
        ...state,
        partner: action.payload,
      };
    case SET_ONE_PARTNER:
      return {
        ...state,
        one_partner: action.payload,
      };
    case DELETE_PARTNER:
      return {
        ...state,
        partner: state.partner.filter(
          (one_partner) => one_partner.id !== action.payload
        ),
      };
    case GET_TN:
      return {
        ...state,
        tn: action.payload.result,
      };

    case GET_ONE_TN:
      return {
        ...state,
        one_tn: action.payload,
      };
    case SET_TN:
      return {
        ...state,
        tn: action.payload,
      };
    case SET_ONE_TN:
      return {
        ...state,
        one_tn: action.payload,
      };
    case SET_LOADER:
      return {
        ...state,
        loading: true,
      };

    case CLEAR_EH:
      return {
        ...state,
        kurse: [],
        one_kurs: null,
        tn: [],
        one_tn: null,
        partner: [],
        one_partner: null,
        loading: false,
      };
    case REMOVE_MESSAGE:
      return {
        ...state,
        is_message: false,
      };
    default:
      return state;
  }
};

export default ehReducer;
