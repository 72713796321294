import React, { Fragment } from 'react';
import WebsiteAccordion from './WebsiteAccordion';
import WebsiteBezahlungNovalnet from './WebsiteBezahlungNovalnet';
const WebsiteBezahlungAccordion = ({ to_payment, bank, payments, config }) => {
  if (config.payment_2 === '' && config.payment_3 === '') {
    return (
      <Fragment>
        {config.payment_1 !== '' && (
          <WebsiteAccordion
            data={payments[config.payment_1]}
            config={config}
            payments={payments}
            to_payment={to_payment}
            bank={bank}
            is_open={true}
            is_alone={true}
          />
        )}
      </Fragment>
    );
  }
  return (
    <div className="accordion-container">
      <Fragment>
        {config.payment_1 !== '' && (
          <WebsiteAccordion
            data={payments[config.payment_1]}
            config={config}
            payments={payments}
            to_payment={to_payment}
            bank={bank}
            is_open={true}
            is_alone={false}
          />
        )}

        {config.payment_2 !== '' && (
          <WebsiteAccordion
            data={payments[config.payment_2]}
            to_payment={to_payment}
            payments={payments}
            config={config}
            bank={bank}
            is_novalnet={false}
            is_open={false}
            is_alone={false}
          />
        )}
        {config.payment_3 !== '' && (
          <WebsiteAccordion
            data={payments[config.payment_3]}
            to_payment={to_payment}
            payments={payments}
            config={config}
            bank={bank}
            is_novalnet={false}
            is_open={false}
            is_alone={false}
          />
        )}
      </Fragment>
    </div>
  );
};

export default WebsiteBezahlungAccordion;
