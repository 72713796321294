import React, { Fragment } from 'react';

const WebsiteBezahlungNovalnet = ({ to_payment, payments, config }) => {
  return (
    <div className="website-bezahlung-novalnet">
      <div className="first-row">
        <div className="box-container">
          {config.novalnet_payment_1 !== '' && (
            <Box
              data={payments[config.novalnet_payment_1]}
              to_payment={to_payment}
            />
          )}
        </div>

        <div className="box-container">
          {config.novalnet_payment_2 !== '' && (
            <Box
              data={payments[config.novalnet_payment_2]}
              to_payment={to_payment}
            />
          )}
        </div>
      </div>
      <div className="second-row">
        <div className="box-container">
          {config.novalnet_payment_3 !== '' && (
            <Box
              data={payments[config.novalnet_payment_3]}
              to_payment={to_payment}
            />
          )}
        </div>
        <div className="box-container">
          {config.novalnet_payment_4 !== '' && (
            <Box
              data={payments[config.novalnet_payment_4]}
              to_payment={to_payment}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const Box = ({ data, to_payment }) => {
  const { title, type, img_src } = data;
  return (
    <Fragment>
      <div
        className="box"
        onClick={() => {
          to_payment(type, 'novalnet');
        }}
      >
        <div className="img-part">
          {img_src !== null && (
            <div className={'img-container ' + type}>
              <img
                src={img_src}
                alt="Ein Kurs zur Ersten Hilfe"
                className={type}
              />
            </div>
          )}
        </div>

        <div className="title">{title}</div>
      </div>
    </Fragment>
  );
};

export default WebsiteBezahlungNovalnet;
