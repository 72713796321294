export default class Times {
  static parseDate(input, time = false) {
    var parts = input.match(/(\d+)/g);
    this.parseTime('8:00');
    var date = new Date(parts[2], parts[1] - 1, parts[0]);
    if (time) {
      parts = this.parseTime(time);
      date.setHours(parts[0], parts[1]);
      return date;
    } else {
      return date;
    }
  }

  static get_month(date, is_german = true) {
    const months = [
      'Januar',
      'Februar',
      'März',
      'April',
      'Mai',
      'Juni',
      'Juli',
      'August',
      'September',
      'Oktober',
      'November',
      'Dezember',
    ];
    if (is_german) {
      const real_date = this.parseDate(date);
      return months[+real_date.getMont()];
    } else {
      return months[+date.getMonth()];
    }
  }
  static get_weekday(date, is_german = true) {
    const full_days = [
      'Sonntag',
      'Montag',
      'Dienstag',
      'Mittwoch',
      'Donnerstag',
      'Freitag',
      'Samstag',
    ];
    if (is_german) {
      const real_date = this.parseDate(date);
      return full_days[+real_date.getDay()];
    } else {
      return full_days[+date.getDay()];
    }
  }
  static parseTime(input) {
    var parts = input.match(/(\d+)/g);
    return parts;
  }

  static to_german_date(date) {
    const zeroToInt = (x) => {
      var out = String(x);
      if (out.length === 1) {
        out = '0' + out;
      }
      return out;
    };
    return `${zeroToInt(date.getDate())}.${zeroToInt(
      date.getMonth() + 1
    )}.${date.getFullYear()}`;
  }

  zeroToInt(x) {
    var out = String(x);
    if (out.length === 1) {
      out = '0' + out;
    }
    return out;
  }
}
