import React, { Fragment } from 'react';
import Times from '../../utils/Times';

const UmbuchenShowcase = ({ one_kurs, rest_plaetze, warteliste, submit }) => {
  var open_html = '';
  if (one_kurs.open > rest_plaetze) {
    open_html = <p className="platz">freie Plätze</p>;
  } else if (one_kurs.open <= rest_plaetze && one_kurs.open > 0) {
    open_html = <p className="platz wenig">wenige Plätze</p>;
  } else {
    open_html = <p className="platz booked">ausgebucht</p>;
  }
  return (
    <Fragment>
      {one_kurs !== null && (
        <div className="umbuchen-showcase">
          <div className="row">
            <div className="col-md-3 image_container">
              <img
                src="/img/kurse/kurse_unter.jpg"
                alt="Ein Kurs zur Ersten Hilfe"
              />
            </div>
            <div className="col-md-9 z-info">
              <div className="main_content">
                <div className="termin_container">
                  {one_kurs.date === 'Warteliste' ? (
                    <h3 className="termin">Warteliste {one_kurs.stadt}</h3>
                  ) : (
                    <Fragment>
                      <h3 className="termin">
                        {Times.get_weekday(one_kurs.date)} {one_kurs.date}
                      </h3>
                      <p className="hour">
                        Von {one_kurs.info.start} Uhr bis {one_kurs.info.end}{' '}
                        Uhr
                      </p>
                    </Fragment>
                  )}
                </div>
              </div>
              <div className="second-content">
                <div className="left-part">
                  {one_kurs.dozent_name !== '' ? (
                    <p className="kurs_leitung">Mit {one_kurs.dozent_name}</p>
                  ) : (
                    ''
                  )}
                  <p className="adress-name">{one_kurs.info.street_name}</p>
                  <p className="street">{one_kurs.info.street}</p>
                  <p className="plz">
                    {one_kurs.info.plz} {one_kurs.info.city}
                  </p>
                </div>
                {warteliste === false && (
                  <div className="right-part">
                    <div className="platz_container">{open_html}</div>

                    <div className="check_container">
                      <div className="preis_container">
                        <p className="preis">
                          noch {one_kurs.open}{' '}
                          {one_kurs.open === 1 ? 'Platz' : 'Plätze'}
                        </p>
                      </div>

                      <div className="btn_container">
                        <button
                          onClick={() => {
                            submit(one_kurs.id);
                          }}
                        >
                          Umbuchen
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                {warteliste === true && (
                  <div className="right-part warteliste-right-part">
                    <div className="btn_container">
                      <button
                        onClick={() => {
                          submit(one_kurs.id);
                        }}
                      >
                        Umbuchen
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default UmbuchenShowcase;
