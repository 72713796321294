import React, { useContext, useEffect, Fragment } from 'react';
import axios from 'axios';
import { handlersContext } from '../../context/handlers/HandlersState';
import { ehContext } from '../../context/eh/EhState';
import { authContext } from '../../context/auth/AuthState';
import { useHistory } from 'react-router-dom';
import UmbuchenShowcase from './UmbuchenShowcase';
const UmbuchenAdmin = ({ id, action }) => {
  const history = useHistory();
  const auth_Context = useContext(authContext);
  const { user_status } = auth_Context;
  const eh_Context = useContext(ehContext);
  const {
    kurse,
    get_kurse,
    get_warteliste,
    one_tn,
    get_one_tn,
    rest_plaetze,
  } = eh_Context;
  const handlers_Context = useContext(handlersContext);
  const { set_loader, set_message, remove_loader, wait } = handlers_Context;
  useEffect(() => {
    get_kurse();
    get_warteliste();
    get_one_tn(id);
    // eslint-disable-next-line
  }, []);

  const umbuchen_sumbmit = async (kurs_id) => {
    try {
      set_loader();
      const data = {
        tn_id: id,
        kurs_id: kurs_id,
      };
      if (action === 'too_late') {
        await axios.put('/umbuchen_api/umbuchen?payment=1', data);
      } else {
        await axios.put('/umbuchen_api/umbuchen', data);
      }

      await wait(1000);
      remove_loader();
      history.goBack();
    } catch (err) {
      set_message(err.response.data.error_text);
      remove_loader();
    }
  };

  const auf_warteliste_too_late = async () => {
    try {
      set_loader();
      const data = {
        tn_id: id,
      };
      await axios.put('/umbuchen_api/warteliste?payment=1', data);
      await wait(1000);
      remove_loader();
      history.goBack();
    } catch (err) {
      set_message(err.response.data.error_text);
      remove_loader();
    }
  };
  const trozdem_to_kurs = async () => {
    try {
      set_loader();
      const data = {
        tn_id: id,
      };
      await axios.put('/umbuchen_api/trozdem_umbuchen', data);
      await wait(1000);
      remove_loader();
      history.goBack();
    } catch (err) {
      set_message(err.response.data.error_text);
      remove_loader();
    }
  };
  return (
    <Fragment>
      <section className="umbuchen-admin">
        <div className="container">
          <div className="text-center py-3">
            <button
              onClick={() => {
                history.goBack();
              }}
            >
              Zurück
            </button>
          </div>

          <div className="title">
            {one_tn !== null && (
              <span>
                Teilnehmer: {one_tn.info.vorname} {one_tn.info.nachname} (
                {one_tn.info.birthday})
              </span>
            )}
          </div>
          {action === 'too_late' ? (
            <div className="desc">
              Der Teilnehmer hat leider zu spät gezahlt und der Kurs ist bereits
              ausgebucht. Du kannst dem Teilnehmer hier einen neuen Kurs
              zuteilen oder in zunächst auf die Warteliste schreiben. Er bekommt
              dann eine E-Mail, in der er angewiesen wird, sich einen neuen Kurs
              zuwählen.
              <p className="text-center py-2">
                <button
                  onClick={() => {
                    auf_warteliste_too_late();
                  }}
                >
                  Auf Warteliste
                </button>
              </p>
              {user_status === 'admin' && (
                <p className="text-center py-2">
                  <button
                    className="btn-red"
                    onClick={() => {
                      trozdem_to_kurs();
                    }}
                  >
                    Trotzdem hinzufügen
                  </button>
                </p>
              )}
              <p className="text-center py-2">
                Bisheriger Kurs: {one_tn.date} in {one_tn.stadt}
              </p>
            </div>
          ) : (
            <div className="desc">
              Du kannst den Teilnehmer hier einem neuen Kurs zuweisen
              {one_tn !== null && (
                <p className="text-center">
                  Bisheriger Kurs: {one_tn.date} in {one_tn.stadt}
                </p>
              )}
            </div>
          )}

          <div className="kurse-container">
            <div className="text-center sub-title">Alle Kurse</div>
            {kurse !== [] &&
              kurse.map((one_kurs, index) => {
                return (
                  <UmbuchenShowcase
                    key={index}
                    one_kurs={one_kurs}
                    warteliste={false}
                    rest_plaetze={rest_plaetze}
                    submit={umbuchen_sumbmit}
                  />
                );
              })}
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default UmbuchenAdmin;
