import React, { Fragment, useEffect, useState, useRef } from 'react';
import Times from '../../utils/Times';
const Calendar = ({ name, state, setState, error }) => {
  let ref = useRef();
  const [current_days, setCurrent_Days] = useState([]);
  const [current_date, setCurrent_Date] = useState(new Date());

  const [active, setActive] = useState('');
  const render = (dt) => {
    dt.setDate(1);
    // an welchem wochentag ist 1 des Monats
    var day = dt.getDay();
    var today = new Date();
    // last day of current month
    var endDate = new Date(dt.getFullYear(), dt.getMonth() + 1, 0).getDate();
    // last day of month before 31,30,29,28 oder
    var prevDate = new Date(dt.getFullYear(), dt.getMonth(), 0).getDate();
    // wenn auf sonntag 1 fällt
    if (day === 0) {
      day = 7;
    }
    var cells = [];
    for (let x = day; x > 1; x--) {
      cells.push(
        <div key={-x} className="prev_date">
          {prevDate - x + 1}
        </div>
      );
    }
    for (let i = 1; i <= endDate; i++) {
      if (
        i === today.getDate() &&
        dt.getMonth() === today.getMonth() &&
        dt.getFullYear() === today.getFullYear()
      ) {
        cells.push(
          <div key={i} className="day today">
            {i}
          </div>
        );
      } else {
        cells.push(
          <div key={i} className="day">
            {i}
          </div>
        );
      }
    }
    setCurrent_Days(cells);
  };
  const toogle = () => {
    if (active === 'active') {
      setActive('');
    } else {
      setActive('active');
    }
  };

  const move_month = (para) => {
    var new_date = new Date(
      current_date.getFullYear(),
      current_date.getMonth() - 1,
      1
    );
    if (para === 'prev') {
      new_date = new Date(
        current_date.getFullYear(),
        current_date.getMonth() - 1,
        1
      );
    } else if (para === 'next') {
      new_date = new Date(
        current_date.getFullYear(),
        current_date.getMonth() + 1,
        1
      );
    }
    render(new_date);
    setCurrent_Date(new_date);
  };

  const choose_day = (e) => {
    if (e.target.classList.contains('day')) {
      const day = e.target.innerText;
      setState({
        ...state,
        [name]: Times.to_german_date(
          new Date(current_date.getFullYear(), current_date.getMonth(), day)
        ),
      });
      setCurrent_Date(
        new Date(current_date.getFullYear(), current_date.getMonth(), day)
      );

      setActive('');
    }
  };
  useEffect(() => {
    render(current_date);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let outside_click = (e) => {
      if (!ref.current.contains(e.target)) {
        setActive('');
      }
    };
    document.addEventListener('mousedown', outside_click);
    return () => {
      document.removeEventListener('mousedown', outside_click);
    };
  });
  return (
    <Fragment>
      <div className="calendar">
        <div ref={ref} className="pop-up-calendar-container">
          <div
            className={
              error === true && state[name] === false
                ? 'termin error'
                : 'termin'
            }
            onClick={toogle}
          >
            <span className="choosen-day">
              {state[name] === false
                ? 'Datum'
                : ` ${Times.get_weekday(state[name], true)} ${state[name]}`}
            </span>
            <i className="fas fa-caret-down"></i>
          </div>
          <div className={`pop-up-calendar ${active}`}>
            <div className="month">
              <div className="prev" onClick={() => move_month('prev')}>
                <i className="fas fa-chevron-left"></i>
              </div>
              <div>
                <p className="current_month">
                  {Times.get_month(current_date, false)}
                </p>
                <p className="current_date">
                  {Times.to_german_date(current_date)}
                </p>
              </div>
              <div className="next" onClick={() => move_month('next')}>
                <i className="fas fa-chevron-right"></i>
              </div>
            </div>
            <div className="weekdays">
              <div>Mo</div>
              <div>Di</div>
              <div>Mi</div>
              <div>Do</div>
              <div>Fr</div>
              <div>Sa</div>
              <div>So</div>
            </div>
            <div className="days" onClick={choose_day}>
              {current_days}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Calendar;
