import React, { Fragment, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { ehContext } from '../../context/eh/EhState';
import { handlersContext } from '../../context/handlers/HandlersState';
import { authContext } from '../../context/auth/AuthState';
import ConfirmStep from '../handlers/ConfirmStep';

const KursExtra = () => {
  const { one_kurs } = useContext(ehContext);
  const auth_Context = useContext(authContext);
  const { user_status } = auth_Context;
  const handlers_Context = useContext(handlersContext);
  const { set_message, set_loader, remove_loader } = handlers_Context;
  const [is_dozent, setIs_Dozent] = useState(false);

  const [confirmBescheinigun, setConfirmBescheinigun] = useState({
    question:
      'Möchtest du die Bescheinigungen nur für die Teilnehmer herunterladen, die tatsächlich am Kurs teilgenommen haben?',
    confirm_text: 'Ja',
    reject_text: 'Nein',
    answer: false,
    answer_reject: false,
    active: false,
    type: '',
    extra_info: null,
    outside_click: false,
  });
  useEffect(() => {
    if (confirmBescheinigun.answer === true) {
      download('bescheinigung_teilgenommen');
      setConfirmBescheinigun({
        ...confirmBescheinigun,
        answer: false,
      });
    }

    if (confirmBescheinigun.answer_reject === true) {
      download('bescheinigung');
      setConfirmBescheinigun({
        ...confirmBescheinigun,
        answer_reject: false,
      });
    }

    // eslint-disable-next-line
  }, [confirmBescheinigun.answer, confirmBescheinigun.answer_reject]);

  const [confirmBescheinigunVorlage, setConfirmBescheinigunVorlage] = useState({
    question:
      'Möchtest du die Bescheinigungen nur für die Teilnehmer herunterladen, die tatsächlich am Kurs teilgenommen haben?',
    confirm_text: 'Ja',
    reject_text: 'Nein',
    answer: false,
    answer_reject: false,
    active: false,
    type: '',
    extra_info: null,
    outside_click: false,
  });
  useEffect(() => {
    if (confirmBescheinigunVorlage.answer === true) {
      download('bescheinigung_vorlage_teilgenommen');
      setConfirmBescheinigunVorlage({
        ...confirmBescheinigunVorlage,
        answer: false,
      });
    }

    if (confirmBescheinigunVorlage.answer_reject === true) {
      download('bescheinigung_vorlage');
      setConfirmBescheinigunVorlage({
        ...confirmBescheinigunVorlage,
        answer_reject: false,
      });
    }

    // eslint-disable-next-line
  }, [
    confirmBescheinigunVorlage.answer,
    confirmBescheinigunVorlage.answer_reject,
  ]);
  useEffect(() => {
    const check_if_dozent = async () => {
      try {
        const res = await axios.get(
          `/users_api/is_users?kurs_id=${one_kurs.id}`
        );
        setIs_Dozent(res.data.is_dozent === 1 ? true : false);
        // setBezahlung(res.data.result);
      } catch (err) {
        console.log(err.response.data);
        set_message(err.response.data.error_text);
        return;
      }
    };
    if (one_kurs !== null) {
      check_if_dozent();
    }
    // eslint-disable-next-line
  }, [one_kurs]);
  const download = async (name) => {
    try {
      set_loader();
      const res = await axios.get(
        `/kurse_api/downloads/${name}?id=${one_kurs.id}`,
        {
          responseType: 'blob',
          headers: {
            Accept: 'application/pdf',
          },
        }
      );
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      var file_name = `${one_kurs.stadt}-${one_kurs.date}.pdf`;
      link.setAttribute('download', file_name.toUpperCase());
      document.body.appendChild(link);
      link.click();
      remove_loader();
    } catch (err) {
      console.log(err.response.data);
      set_message(err.response.data.error_text);
      remove_loader();
      return;
    }
  };
  return (
    <Fragment>
      <ConfirmStep
        confirm={confirmBescheinigun}
        setConfirm={setConfirmBescheinigun}
      />
      <ConfirmStep
        confirm={confirmBescheinigunVorlage}
        setConfirm={setConfirmBescheinigunVorlage}
      />
      {one_kurs !== null && is_dozent === true && (
        <Fragment>
          <div className="kurs-extra">
            <p className="text-center pb-2">Adminbereich</p>

            {user_status === 'admin' && (
              <p className="text-center statistik">
                bezahlt: <span className="color-1">{one_kurs.bezahlt}</span>{' '}
                gebucht :<span className="color-1">{one_kurs.gebucht}</span>
                freie Plätze :<span className="color-1">{one_kurs.open}</span>
              </p>
            )}

            <hr />

            <p className="text-center pb-2">
              Dokumente drucken für gesamten Kurs
            </p>
            <div className="btn-container-3 ">
              <button className="btn-blue" onClick={() => download('quittung')}>
                Quittung
              </button>
              <button
                className="btn-blue"
                onClick={() =>
                  setConfirmBescheinigun({
                    ...confirmBescheinigun,
                    active: true,
                  })
                }
              >
                Bescheinigung
              </button>
              <button
                className="btn-blue"
                onClick={() => download('datenblatt')}
              >
                Datenblatt
              </button>
            </div>
            <div className="btn-container-3 pt-2">
              <button
                className="btn-blue"
                onClick={() =>
                  setConfirmBescheinigunVorlage({
                    ...confirmBescheinigunVorlage,
                    active: true,
                  })
                }
              >
                Bescheinigung mit Vorlage
              </button>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default KursExtra;
