import React, { Fragment, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import KursShowcase from '../kurse/KursShowcase';
import { ehContext } from '../../context/eh/EhState';
import TnTable from '../tn/TnTable';
import KursExtra from '../kurse/KursExtra';
import BackButton from '../layout/BackButton';

const OneKurs = ({ id }) => {
  const eh_Context = useContext(ehContext);
  const { get_one_kurs, set_tn } = eh_Context;
  const [pagination, setPagination] = useState({
    page: 1,
    count: false,
    tn_pro_seite: false,
    type: 0,
    direction: 'DESC',
    name: '',
    date: '',
  });

  useEffect(() => {
    const get_tn = async () => {
      try {
        const table_info = await axios.get(
          `/teilnehmer_api/table_info?kurs_id=${id}`
        );
        const res_kurs = await axios.get(`/teilnehmer_api?kurs_id=${id}`);
        const result = res_kurs.data.result;
        if (result === null) {
          return;
        } else {
          set_tn(result);
          setPagination({
            ...pagination,
            count: table_info.data.count,
            tn_pro_seite: table_info.data.tn_pro_seite,
          });
        }
      } catch (error) {
        console.log(error.response.data);
        return;
      }
    };

    get_one_kurs(id);

    get_tn();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const get_tn = async () => {
      try {
        const res_kurs = await axios.get(
          `/teilnehmer_api?kurs_id=${id}&page=${pagination.page}&sort_type=${pagination.type}&sort_direction=${pagination.direction}`
        );
        const result = res_kurs.data.result;
        if (result === null) {
          return;
        } else {
          set_tn(result);
        }
      } catch (error) {
        console.log(error.response.data);
        return;
      }
    };

    get_tn();
    // eslint-disable-next-line
  }, [pagination]);
  return (
    <Fragment>
      <div className="container pb-5">
        <BackButton padding={'pt-2'} />
        <KursShowcase />
        <KursExtra />
        <TnTable pagination={pagination} setPagination={setPagination} />
      </div>
    </Fragment>
  );
};

export default OneKurs;
