import React, { useContext, useEffect, useState, Fragment } from 'react';
import KurseRow from './KurseRow';
import BoxKurs from './BoxKurs';
import { ehContext } from '../../context/eh/EhState';
const KurseTable = ({ props }) => {
  const eh_Context = useContext(ehContext);
  const { get_kurse, kurse } = eh_Context;

  useEffect(() => {
    get_kurse();
    // eslint-disable-next-line
  }, []);

  const [box, setBox] = useState(false);
  return (
    <Fragment>
      <BoxKurs box={box} setBox={setBox} props={props} />
      <div className="responsive-table">
        <table>
          <thead>
            <tr>
              <th>Stadt</th>
              <th>Datum</th>
              <th className="rp-col-1">Uhrzeit</th>
              <th className="rp-col-2">noch offen</th>
            </tr>
          </thead>
          <tbody>
            {kurse.map((kurs, index) => {
              return <KurseRow key={index} kurs={kurs} setBox={setBox} />;
            })}
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

export default KurseTable;
