import React, { useContext, useEffect, useState, Fragment } from 'react';
import axios from 'axios';
import jump from 'jump.js';
import Checks from '../../utils/Checks';
import { handlersContext } from '../../context/handlers/HandlersState';
import { ehContext } from '../../context/eh/EhState';
import { authContext } from '../../context/auth/AuthState';
import { useHistory } from 'react-router-dom';
import KursShowcase from '../kurse/KursShowcase';
import TeilnehmerForm from './TeilnehmerForm';
import ImagePopUp from '../handlers/ImagePopUp';
import TeilnehmerExtra from './TeilnehmerExtra';
import ConfirmStep from '../handlers/ConfirmStep';
import TeilnehmerGutschein from './TeilnehmerGutschein';
import BackButton from '../layout/BackButton';

// fehler bei extra fahrzeug auch anzeigen
const TeilnehmerAdmin = ({ id, action }) => {
  const eh_Context = useContext(ehContext);
  const { get_one_kurs, get_one_tn, one_tn, one_kurs } = eh_Context;
  const auth_Context = useContext(authContext);
  const { user_status } = auth_Context;
  const handlers_Context = useContext(handlersContext);
  const {
    set_loader,
    set_message,
    remove_loader,
    wait,
    is_clicked,
    set_is_clicked,
  } = handlers_Context;
  let history = useHistory();
  const [error, setError] = useState(false);
  const [error_text, setError_Text] = useState('');
  const [state, setState] = useState({
    bezahlt: 0,
    teilgenommen: 0,
    //in info
    anrede: false,
    vorname: false,
    nachname: false,
    street: false,
    plz: false,
    city: false,
    birthday: false,
    email: false,
    phone: false,
    notiz: '',
    // extra
    gutschein: '',
  });
  const [confirm, setConfirm] = useState({
    question:
      'Möchtest du einenen weiteren Teilnehmer für diesen Kurs erstellen?',
    confirm_text: 'Bestätigen',
    reject_text: 'Verwerfen',
    answer: false,
    answer_reject: false,
    active: false,
    type: 'incomplete_data_tn',
    extra_info: null,
    outside_click: true,
  });

  const [confirmTNplus1, setConfirmTNplus1] = useState({
    question:
      'Der Kurs ist ausgebucht. Möchtest du den Kurs um einen Platz erhöhen?',
    confirm_text: 'Bestätigen',
    reject_text: 'Verwerfen',
    answer: false,
    answer_reject: false,
    active: false,
    type: 'tnplus1',
    extra_info: null,
    outside_click: true,
  });
  const [confirm_weiterer_tn, setConfirm_Weiterer_Tn] = useState({
    question:
      'Möchtest du einenen weiteren Teilnehmer für diesen Kurs erstellen?',
    confirm_text: 'Bestätigen',
    reject_text: 'Verwerfen',
    answer: false,
    answer_reject: false,
    active: false,
    type: '',
    extra_info: null,
    outside_click: false,
  });
  useEffect(() => {
    if (action === 'create') {
      get_one_kurs(id);
    } else if (action === 'edit') {
      get_one_tn(id);
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (action === 'create' && one_kurs !== null) {
      if (one_kurs.open <= 0) {
        if (user_status === 'admin') {
          setConfirmTNplus1({ ...confirmTNplus1, active: true });
        } else {
          set_message(
            `Der Kurs am ${one_kurs.date} in ${one_kurs.stadt} ist leider ausgebucht!`
          );
          history.push('/fahrschule');
        }
      }
    }
    // eslint-disable-next-line
  }, [one_kurs]);

  useEffect(() => {
    if (action === 'edit' && one_tn !== null) {
      get_one_kurs(one_tn.kurs_id);
      setState({
        ...state,
        ...one_tn.info,
        bezahlt: one_tn.bezahlt,
        teilgenommen: one_tn.teilgenommen,
      });
      setError(true);
    }
    // eslint-disable-next-line
  }, [one_tn]);

  const submit = async () => {
    if (is_clicked === true) {
      console.log('Double click');
      return;
    }
    set_is_clicked(true);
    setError(true);
    var valid = true;
    for (const prop in state) {
      if (
        (state[prop] === false || state[prop] === '') &&
        prop !== 'notiz' &&
        prop !== 'gutschein'
      ) {
        valid = false;
      }
    }

    var error_text = 'Bitte fülle alle Felder aus!';
    var extra_checks = true;
    if (!Checks.email(state.email)) {
      error_text = 'Bitte wähle eine gültige E-Mail-Adresse';
      extra_checks = false;
    } else if (!Checks.phone(state.phone)) {
      error_text = 'Bitte wähle eine gültige Telefonnummer';
      extra_checks = false;
    } else if (!Checks.plz(state.plz)) {
      error_text = 'Bitte wähle eine gültige Postleitzahl';
      extra_checks = false;
    } else if (!Checks.birthday(state.birthday)) {
      error_text = 'Bitte wähle ein gültiges Geburtsdatum';
      extra_checks = false;
    }
    if (valid === false && extra_checks === false) {
      error_text = 'Bitte fülle alle Felder aus!';
    }

    if (valid === false || extra_checks === false) {
      console.log(state);

      if (user_status === 'admin' && data_test_4_admin()) {
        // noch test für admin
        var question = '';
        if (action === 'create') {
          question =
            'Möchtest du den Teilnehmer mit unvollständigen Daten erstellen?';
        } else {
          question =
            'Möchtest du den Teilnehmer mit unvollständigen Daten speichern?';
        }
        setConfirm({
          ...confirm,
          question: question,
          active: true,
        });
      }
      setError_Text(error_text);
      return set_is_clicked(false);
    } else {
      await send();
    }
  };

  const data_test_4_admin = () => {
    var admin_checks = true;
    if (!Checks.email(state.email)) {
      admin_checks = false;
    } else if (state.vorname === false || state.vorname === '') {
      admin_checks = false;
    } else if (state.nachname === false || state.nachname === '') {
      admin_checks = false;
    } else if (state.anrede === false || state.anrede === '') {
      admin_checks = false;
    } else if (!Checks.birthday(state.birthday)) {
      admin_checks = false;
    }
    return admin_checks;
  };

  useEffect(() => {
    if (confirm.answer === true) {
      if (confirm.type === 'incomplete_data_tn') {
        send();
      }
    }

    // eslint-disable-next-line
  }, [confirm.answer]);

  useEffect(() => {
    const fct = async () => {
      setConfirmTNplus1({
        ...confirmTNplus1,
        answer: false,
      });
      set_loader();
      try {
        await axios.get(`/kurse_api/add_platz?id=${id}`);
        await wait(1000);
        remove_loader();
        window.location.reload();
      } catch (err) {
        console.log(err.response.data);
        set_message(err.response.data.error_text);
        remove_loader();
      }
    };
    if (confirmTNplus1.answer === true) {
      fct();
    }
    if (confirmTNplus1.answer_reject === true) {
      setConfirmTNplus1({
        ...confirmTNplus1,
        answer_reject: false,
      });
      history.push('/fahrschule');
    }

    // eslint-disable-next-line
  }, [confirmTNplus1.answer, confirmTNplus1.answer_reject]);

  useEffect(() => {
    if (confirm_weiterer_tn.answer === true) {
      jump('.teilnehmer-admin', {
        duration: (distance) => Math.abs(distance) * 2,
      });
      window.location.reload();
    }

    if (confirm_weiterer_tn.answer_reject === true) {
      history.push('/fahrschule');
    }

    // eslint-disable-next-line
  }, [confirm_weiterer_tn.answer, confirm_weiterer_tn.answer_reject]);

  const send = async () => {
    setError_Text('');
    set_loader();
    try {
      if (action === 'create') {
        await axios.post(`/teilnehmer_api?kurs_id=${id}`, state);
      } else if (action === 'edit') {
        await axios.put(`/teilnehmer_api?&id=${one_tn.id}`, state);
      } else {
        console.log(error);
        return set_is_clicked(false);
      }
      await wait(1000);
      remove_loader();
      if (action === 'edit') {
        history.goBack();
        return set_is_clicked(false);
      } else {
        setConfirm_Weiterer_Tn({
          ...confirm_weiterer_tn,
          active: true,
        });
      }
      return set_is_clicked(false);
    } catch (err) {
      console.log(err.response.data);
      if (err.response.data.hasOwnProperty('error_type')) {
        if (err.response.data.error_type === 'gutschein') {
          setState({
            ...state,
            gutschein: '',
          });
          set_message(err.response.data.error_text);
        } else {
          set_message(err.response.data.error_text);
        }
      } else {
        set_message(err.response.data.error_text);
      }

      remove_loader();
      return set_is_clicked(false);
    }
  };

  return (
    <Fragment>
      <ConfirmStep confirm={confirm} setConfirm={setConfirm} />
      <ConfirmStep confirm={confirmTNplus1} setConfirm={setConfirmTNplus1} />
      <ConfirmStep
        confirm={confirm_weiterer_tn}
        setConfirm={setConfirm_Weiterer_Tn}
      />
      <ImagePopUp />
      <section className="teilnehmer-admin">
        <div className="container pb-2">
          <BackButton padding={'pt-2'} />
          {action === 'create' ? (
            <h2>Du hast dich für folgenden Kurs entschieden</h2>
          ) : (
            <h2 className="text-center">
              Teilnehmer: {state.vorname} {state.nachname} ({state.birthday})
            </h2>
          )}

          <KursShowcase />

          {action === 'edit' && (
            <TeilnehmerExtra state={state} setState={setState} id={id} />
          )}
          <TeilnehmerForm
            state={state}
            setState={setState}
            error={error}
            one_tn={one_tn}
            action={action}
          />
          <div className="py-3"></div>

          {action === 'edit' ? (
            <div className="pb-5">
              <div className="text-center py-5">
                <div
                  className={
                    error_text !== ''
                      ? 'error-text pb-2'
                      : 'error-text hide pb-2'
                  }
                >
                  {error_text}
                </div>
                <button onClick={submit} className="btn-green">
                  Speichern
                </button>
              </div>
            </div>
          ) : (
            <Fragment>
              <div className="py-3"></div>
              <TeilnehmerGutschein
                state={state}
                setState={setState}
                submit={submit}
                error_text={error_text}
              />
            </Fragment>
          )}
        </div>
      </section>
    </Fragment>
  );
};

export default TeilnehmerAdmin;
