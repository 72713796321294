import React, { useContext, useEffect, useState, Fragment } from 'react';
import WartelisteRow from './WartelisteRow';
import BoxKurs from './BoxKurs';
import { ehContext } from '../../context/eh/EhState';
const WartelisteTable = ({ props }) => {
  const eh_Context = useContext(ehContext);
  const { get_warteliste, warteliste } = eh_Context;

  useEffect(() => {
    get_warteliste();
    // eslint-disable-next-line
  }, []);

  const [box, setBox] = useState(false);

  return (
    <Fragment>
      {warteliste.length !== 0 && (
        <Fragment>
          <BoxKurs box={box} setBox={setBox} props={props} />
          <div className="responsive-table">
            <table>
              <thead>
                <tr>
                  <th>Stadt</th>
                  <th className="rp-col-2">noch offen</th>
                </tr>
              </thead>
              <tbody>
                {warteliste.map((kurs, index) => {
                  return (
                    <WartelisteRow key={index} kurs={kurs} setBox={setBox} />
                  );
                })}
              </tbody>
            </table>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default WartelisteTable;
