import React, { Fragment, useContext } from 'react';
import KurseTable from '../kurse/KurseTable';
import WartelisteTable from '../kurse/WartelisteTable';
import { authContext } from '../../context/auth/AuthState';
import { useHistory } from 'react-router-dom';

const Home = (props) => {
  const history = useHistory();
  const auth_Context = useContext(authContext);
  const { user_status } = auth_Context;

  return (
    <Fragment>
      <div className="container">
        <div className="text-center pb-4"></div>
        <h1 className="text-center">Erste-Hilfe Kurse</h1>
        {user_status === 'admin' && (
          <div className="text-center pt-2">
            <button onClick={() => history.push('/fahrschule/neuer-kurs')}>
              Neuer Kurs
            </button>
          </div>
        )}

        <KurseTable props={props} />
        {user_status === 'admin' && (
          <div className="text-center pb-2">
            bei noch offen: (bezahlt/gebucht/insgesamt)
          </div>
        )}

        <h1 className="text-center pt-2">Warteliste</h1>
        {user_status === 'admin' && (
          <div className="text-center pt-1">
            <button onClick={() => history.push('/fahrschule/neue-warteliste')}>
              Warteliste erstellen
            </button>
          </div>
        )}

        <WartelisteTable props={props} />
        <div className="pb-5"></div>
      </div>
    </Fragment>
  );
};

export default Home;
