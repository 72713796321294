import React, { Fragment } from 'react';

const TeilnehmerGutschein = ({ submit, error_text, setState, state }) => {
  const onChange = (e) => {
    var { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const onFocus = (e) => {
    e.target.parentNode.classList.remove('error');
    e.target.classList.remove('error');
  };
  return (
    <Fragment>
      <div className="info-kasse captcha-gutschein">
        <div className="sub-title">Gutscheincode</div>
        <div className="input-container pt-2">
          <input
            name="gutschein"
            type="text"
            onChange={onChange}
            onFocus={onFocus}
            value={state.gutschein}
          />
        </div>

        <div className="text-center">
          <div className="text-center py-5">
            <div
              className={
                error_text !== '' ? 'error-text pb-2' : 'error-text hide pb-2'
              }
            >
              {error_text}
            </div>
            <button onClick={submit}>zahlungspflichtig bestellen</button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default TeilnehmerGutschein;
