import React, { Fragment, useRef, useEffect } from 'react';
const ConfirmStep = ({ confirm, setConfirm }) => {
  const messageRef = useRef();
  /*
  const [confirm, setConfirm] = useState({
    question: 'Möchtest du den Partner wirklich löschen?',
    confirm_text: 'Bestätigen',
    reject_text: 'Verwerfen',
    answer: false,
    answer_reject: false,
    active: false,
    type: 'new_tn',
    extra_info : 2
    outside_click:true
  });
  */
  const { question, confirm_text, reject_text, active } = confirm;
  useEffect(() => {
    let handler = (e) => {
      if (!messageRef.current.contains(e.target)) {
        if (confirm.hasOwnProperty('outside_click')) {
          if (confirm.outside_click === true) {
            setConfirm({
              ...confirm,
              active: false,
              answer: false,
              answer_reject: false,
            });
          }
        } else {
          setConfirm({
            ...confirm,
            active: false,
            answer: false,
            answer_reject: false,
          });
        }
      }
    };
    document.addEventListener('mousedown', handler);
    return () => {
      document.removeEventListener('mousedown', handler);
    };
  });
  const remove = () => {
    setConfirm({
      ...confirm,
      answer_reject: true,
      active: false,
      answer: false,
    });
  };
  const confirmBtn = () => {
    setConfirm({
      ...confirm,
      active: false,
      answer: true,
      answer_reject: false,
    });
  };
  return (
    <Fragment>
      <div className={active ? 'message  active' : 'message'}>
        <div ref={messageRef} className="content">
          <div className="close">
            <i className="far fa-times-circle" onClick={remove}></i>
          </div>
          <p
            className="pt-4 py-2"
            dangerouslySetInnerHTML={{ __html: question }}
          />
          <div className="btn_container">
            <button onClick={remove} className="transparent">
              {reject_text}
            </button>
            <button onClick={confirmBtn}>{confirm_text}</button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ConfirmStep;
